import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Row, Col } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import { toast } from "react-hot-toast";
import Loader from "../Loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from "multiselect-react-dropdown";

import {
  LineItemsSelector,
  getInventories,
  getOfferTypes,
  getLineitemFormats,
  getCountries,
  getGender,
  getAgeRange,
  getDevices,
  getPassionPoint,
  saveLineItem,
  clearsaveLineItem,
  updateLineItem,
  clearupdateLineItem,
} from "../../app/features/LineItemManager/LineItemManagerSlice";

const NewLineItemSaas = (props) => {
  console.log(props?.dealId, "this is thw deal id")
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [lineitem_name, setLineitem_name] = useState("");
  const [lineitem_inventory, setLineitem_inventory] = useState(null);
  const [lineitem_format, setLineitem_format] = useState(null);
  const [lineitem_budget, setLineitem_budget] = useState(null);
  const [lineitem_offer, setLineitem_offer] = useState(null);
  const [lineitem_bid, setLineitem_bid] = useState(null);
  const [lineitem_budget_usd, setLineitem_budget_usd] = useState(null);
  const [lineitem_bid_usd, setLineitem_bid_usd] = useState(null);
  const [lineitem_impression, setLineitem_impression] = useState(null);
  const [lineitem_countries, setLineitem_countries] = useState([]);
  const [lineitem_gender, setLineitem_gender] = useState(null);
  const [lineitem_age, setLineitem_age] = useState([]);
  const [lineitem_device, setLineitem_device] = useState([]);
  const [lineitem_passion_points, setLineitem_passion_points] = useState([]);
  const [loadershown, setloadershown] = useState(false);
  const [age_group, setAge_group] = useState([]);
  const [lineitem_details, setLineitem_details] = useState([]);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [lineitem_passion_points_id, setLineitem_passion_points_id] = useState(
    []
  );
  const [lineitem_device_id, setLineitem_device_id] = useState([]);
  const [lineitem_countries_id, setLineitem_countries_id] = useState([]);
  const [lineitem_age_id, setLineitem_age_id] = useState([]);
  const [youtube_inventory, setYoutube_inventory] = useState(false);
  const [enable_save_button, setEnable_save_button] = useState(false);
  const [lineitem_pacing, setLineitem_pacing] = useState([])
  const [lineitem_pacing_rate, setLineitem_pacing_rate] = useState([])
  const [selected_pacing, setSelected_pacing] = useState(null)
  const [selected_pacing_rate, setSelected_pacing_rate] = useState(null)

  const {
    inventories,
    line_item_formats,
    offer_types,
    countries,
    genders,
    age_range,
    devices,
    passion_points,
    error,
    isSuccesssaveLineItem,
    isErrorsaveLineItem,
    inserted_lineitem_id,
    isSuccessupdateLineItem,
    isErrorupdateLineItem,
  } = useSelector(LineItemsSelector);

  useEffect(() => {
    if (inventories.length == 0) {
      dispatch(getInventories({ token: localStorage.getItem("token") }));
    }
    if (line_item_formats.length == 0) {
      dispatch(getLineitemFormats({ token: localStorage.getItem("token") }));
    }
    if (offer_types.length == 0) {
      dispatch(getOfferTypes({ token: localStorage.getItem("token") }));
    }

    if (countries.length == 0) {
      dispatch(getCountries({ token: localStorage.getItem("token") }));
    }
    if (genders.length == 0) {
      dispatch(getGender({ token: localStorage.getItem("token") }));
    }
    dispatch(getDevices({ token: localStorage.getItem("token") }));
    dispatch(getPassionPoint({ token: localStorage.getItem("token") }));
    dispatch(getAgeRange({ token: localStorage.getItem("token") }));
  }, []);

  //this below function is used to set the selected dates nitin 05/09/2023
  const handleCallback = (start, end) => {
    setStart_date(start.format("YYYY-MM-DD"));
    setEnd_date(end.format("YYYY-MM-DD"));
  };

  const handle_multiselect_countries = (e) => {
    var allSelectedCountries_id = e.map((val) => val.id).filter(Boolean);
    setLineitem_countries_id(allSelectedCountries_id);
  };

  //this below function is used to set the multiple ages this is trigger from the multiselect age range field nitin 07/09/2023
  const handle_multiselect_age_range = (e) => {
    var allSelectedAges = e.map((val) => val.id).filter(Boolean);
    setLineitem_age_id(allSelectedAges);
  };

  //this below function is used to set the multiple devices this is trigger from the devices field nitin 07/09/2023
  const handle_multiselect_devices = (e) => {
    var allSelectedDevices = e.map((val) => val.id).filter(Boolean);
    setLineitem_device_id(allSelectedDevices);
  };

  //this function is used to set the multiple passion points this is trigger from the passion points fields nitin 07/09/2023
  const handle_multiselect_passion_points = (e) => {
    var allSelectedPassionPoints = e.map((val) => val.id).filter(Boolean);
    setLineitem_passion_points_id(allSelectedPassionPoints);
  };

  //    this below function is uded to remove selected countries from thw main array nitin 18/09/2023
  const handle_multiremove_country = (e) => {
    var allSelectedCountries = e.map((val) => val.id);
    setLineitem_countries_id(allSelectedCountries);
  };

  //    this below function is uded to remove selected devices from thw main array nitin 18/09/2023
  const handle_multiremove_device = (e) => {
    var allSelectedDevices = e.map((val) => val.id);
    setLineitem_device_id(allSelectedDevices);
  };

  //    this below function is uded to remove selected ages from thw main array nitin 18/09/2023
  const handle_multiremove_age = (e) => {
    var allSelectedAges = e.map((val) => val.id);
    setLineitem_age_id(allSelectedAges);
  };

  //    this below function is uded to remove selected passion points from thw main array nitin 18/09/2023
  const handle_multiremove_passion_points = (e) => {
    var allSelectedPassionPoints = e.map((val) => val.id);
    setLineitem_passion_points_id(allSelectedPassionPoints);
  };

  //this below function is used to save the new lineitem nitin 05/09/2023
  const handle_save_lineitem = async () => {
    if (lineitem_name == "") {
      toast.error("Lineitem Name can't be empty");
    } else if (lineitem_inventory == null) {
      toast.error("Please Select Inventory Type");
    } else if (lineitem_format == null) {
      toast.error("Please Select Format");
    } else if (start_date == "") {
      toast.error("Please Select Start Date");
    } else if (end_date == "") {
      toast.error("Please Select End Date");
    } else if (lineitem_budget == null) {
      toast.error("Budget Field Can't be empry");
    } else if (
      lineitem_budget >
      lineitem_details?.inversion_budget +
      props?.remaining_budget
    ) {
      toast.error(
        `budget can't be greater than ${lineitem_details?.inversion_budget + props?.remaining_budget
        }`
      );
    } else if (lineitem_offer == null) {
      toast.error("Please Select Offer Type");
    } else if (lineitem_bid == null) {
      toast.error("Bid can't be empty");
    } else if (lineitem_countries_id.length == 0) {
      toast.error("Please select country");
    } else if (lineitem_gender == null) {
      toast.error("Please select gender");
    } else if (lineitem_device_id.length == 0) {
      toast.error("please select device");
    } else if (lineitem_age_id.length == 0) {
      toast.error("Please select Age");
    } else {
      setloadershown(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      var raw = JSON.stringify({
        audiences: lineitem_age_id,
        //   "black_list": "",
        campaign_id: props?.campaign_id,
        date_from: start_date,
        date_to: end_date,
        deal_id: props?.dealId,
        format_id: lineitem_format,
        inventory_type_id: lineitem_inventory,
        inversion_budget: lineitem_budget,
        // "inversion_budget_usd": lineitem_budget_usd,
        inversion_goal: lineitem_impression,
        bid_kite_usd: lineitem_bid_usd,
        inversion_goal_usd: lineitem_budget_usd,
        //   "inversion_goal": 100,
        //   "inversion_netMargin": 30,
        inversion_offer_type_id: lineitem_offer,
        bid_kite: lineitem_bid,
        bid_kite_usd: lineitem_bid_usd,
        lineitem_devices: lineitem_device_id,
        lineitem_passion_points: lineitem_passion_points_id,
        name: lineitem_name,
        target_country_id: lineitem_countries_id,
        //   "target_frequency": 10,
        target_gender_id: lineitem_gender,
        pacing_id: selected_pacing ? selected_pacing : null,
        pacing_rate: selected_pacing_rate ? selected_pacing_rate : null,
        //   "white_list": ""
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/update-lineitem/${props?.lineitem_id}`,
          requestOptions
        )
        const result = await response.json();
        if (!result.success) {
          const errorMessage = result.message || "An error occurred";
          throw new Error(errorMessage);
        }
        return toast.success("Lineitem Updated successfully!")
      } catch (error) {
        toast.error(error.message);
      } finally {
        props?.handleClose();
        props?.get_all_lineitems();
        props?.get_selected_campaign_detail();
      }
  }
}

  //this below function is used to remove id 1 from the age group nitin 14/09/2023
  useEffect(() => {
    if (age_range) {
      let ages = [];
      age_range?.map((data) => {
        if (data?.id !== 1) {
          ages.push({
            id: data?.id,
            age: data?.age,
          });
        }
        setAge_group(ages);
      });
    }
  }, [age_range]);

  //this below function is used to get the details of the selected lineitem details nitin 06/09/2023
  useEffect(() => {
    if (props?.lineitem_id) {
      get_selected_lineitem_details();
    }
  }, [props?.lineitem_id]);
  const get_selected_lineitem_details = () => {
    setloadershown(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/getlineitemid/${props?.lineitem_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setLineitem_details(result?.data);
        setloadershown(false);
      })
      .catch((error) => console.log("error", error));
  };

  //this below useEffect is used to store the values in state of edit lineitem we create diffrent modal for edit and new lineitem nitin 06/09/2023
  useEffect(() => {
    if (lineitem_details.length != 0) {
      setLineitem_name(lineitem_details?.name);
      setLineitem_inventory(lineitem_details?.inventoryNavigation?.id);
      setLineitem_format(lineitem_details?.formatNavigation?.id);
      setStart_date(new Date(lineitem_details?.date_from));
      setEnd_date(new Date(lineitem_details?.date_to));
      setLineitem_budget(lineitem_details?.inversion_budget);
      setLineitem_offer(lineitem_details?.offerTypeNavigation?.id);
      setLineitem_bid(lineitem_details?.bid_kite);
      setLineitem_budget_usd(lineitem_details?.inversion_budget_usd);
      setLineitem_bid_usd(lineitem_details?.bid_kite_usd);
      setLineitem_impression(lineitem_details?.estimated_impressions);
      setLineitem_gender(lineitem_details?.targetgender_id);
      setSelected_pacing(lineitem_details?.pacing_id)
      setSelected_pacing_rate(lineitem_details?.pacing_rate)
      // if (lineitem_details?.countryList) {
      // 	let all_countries = []
      // 	lineitem_details?.countryList?.map((data) => {
      // 		all_countries.push(data?.country?.labelKey)
      // 	})
      // 	setLineitem_countries(all_countries)
      // }
      if (lineitem_details?.countryList) {
        let all_countries_id = [];
        lineitem_details?.countryList?.map((data) => {
          all_countries_id.push({
            id: data?.country?.id,
            name: data?.country?.labelKey,
          });
        });
        setLineitem_countries(all_countries_id);
      }
      if (lineitem_details?.countryList) {
        let all_countries_id = [];
        lineitem_details?.countryList?.map((data) => {
          all_countries_id.push(data?.country?.id);
        });
        setLineitem_countries_id(all_countries_id);
      }
      if (lineitem_details?.audienceList) {
        let all_age = [];
        lineitem_details?.audienceList?.map((data) => {
          all_age.push({
            id: data?.audience?.id,
            name: data?.audience?.age,
          });
        });
        setLineitem_age(all_age);
      }
      if (lineitem_details?.audienceList) {
        let all_age_id = [];
        lineitem_details?.audienceList?.map((data) => {
          all_age_id.push(data?.audience?.id);
        });
        setLineitem_age_id(all_age_id);
      }
      if (lineitem_details?.deviceList) {
        let devicesval = [];
        lineitem_details?.deviceList.map((value) => {
          let Deviceindex = devices.findIndex(
            (item) => item.id === value?.deviceId
          );
          if (Deviceindex != -1) {
            devicesval.push(devices[Deviceindex]);
          }
        });
        setLineitem_device(devicesval);
      }
      if (lineitem_details?.deviceList) {
        let devicesval = [];
        lineitem_details?.deviceList.map((value) => {
          devicesval.push(value?.deviceId);
        });
        setLineitem_device_id(devicesval);
      }
      if (lineitem_details?.passionPointList) {
        let passionpnt = [];
        lineitem_details?.passionPointList.map((value) => {
          let passionPointIndex = passion_points.findIndex(
            (item) => item.id === value?.passionpointId
          );
          if (passionPointIndex != -1) {
            passionpnt.push(passion_points[passionPointIndex]);
          }
        });
        setLineitem_passion_points(passionpnt);
      }
      if (lineitem_details?.passionPointList) {
        let passionpnt = [];
        lineitem_details?.passionPointList.map((value) => {
          passionpnt.push(value?.passionpointId);
        });
        setLineitem_passion_points_id(passionpnt);
      }
    }
  }, [lineitem_details]);

  //this below function is used to get the bid usd and budget usd on the basis of kite bid and budget nitin 15/09/2023
  const handle_get_lineitem_bid_usd_budget_usd = () => {
    if (lineitem_bid && lineitem_budget) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      var raw = JSON.stringify({
        deal_id: props?.dealId,
        bid_kite: lineitem_bid,
        inversion_budget: lineitem_budget,
        inversion_offer_type_id: lineitem_offer,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/calculatebudgetusd`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          setLineitem_bid_usd(result?.data?.bid_kite_usd);
          setLineitem_budget_usd(result?.data?.inversion_goal_usd);
          setLineitem_impression(result?.data?.estimated_impressions);
        })
        .catch((error) => console.log("error", error));
    }
  };

  // this below function is used to set the youtube inventory on which basis we are selecting only single country nitin 19/09/2023
  const handle_set_inventory_type_id = (e) => {
    if (e.target.value == 4) {
      setYoutube_inventory(true);
    } else {
      setYoutube_inventory(false);
    }
  };

  // this below function is used to set the youtube inventory when the page loads on which basis we are selecting only single country nitin 19/09/2023
  useEffect(() => {
    if (lineitem_inventory == 4) {
      setYoutube_inventory(true);
    }
  }, [lineitem_inventory]);

  // this function is triggered when we edit the only end date means when the lineitem is active nitin 12/09/2023
  const handleCallbackEdit = (start, end) => {
    setEnd_date(end.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    if (
      lineitem_name !== "" &&
      lineitem_inventory != null &&
      lineitem_format != null &&
      start_date != "" &&
      end_date != "" &&
      lineitem_budget != null &&
      lineitem_offer != null &&
      lineitem_bid != null &&
      lineitem_countries.length != 0 &&
      lineitem_gender != null &&
      lineitem_device.length != 0 &&
      lineitem_age.length != 0
    ) {
      setEnable_save_button(true);
    } else if (
      lineitem_name === "" ||
      lineitem_inventory == null ||
      lineitem_format == null ||
      start_date == "" ||
      end_date == "" ||
      lineitem_budget == null ||
      lineitem_offer == null ||
      lineitem_bid == null ||
      lineitem_countries.length == 0 ||
      lineitem_gender == null ||
      lineitem_device.length == 0 ||
      lineitem_age.length == 0
    ) {
      setEnable_save_button(false);
    }
  }, [
    lineitem_name,
    lineitem_inventory,
    lineitem_format,
    start_date,
    end_date,
    lineitem_budget,
    lineitem_offer,
    lineitem_bid,
    lineitem_countries,
    lineitem_gender,
    lineitem_device,
    lineitem_age,
  ]);

  // this below funcion is used to delete the selected lineitem nitin 20/11/2023
  const handleDeleteConfirm = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/delete-lineItem/${props?.lineitem_id}`, requestOptions)
      .then(response => response.text())
      .then(result => { console.log(result); toast.success("Campaign deleted successfully"); props?.handleClose(); props?.get_all_lineitems(); })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/getpacing`, requestOptions)
      .then(response => response.json())
      .then(result => { console.log(result, "this is the pacing"); setLineitem_pacing(result?.data) })
      .catch(error => toast.error(error));
  }, [])

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/getpacingrate`, requestOptions)
      .then(response => response.json())
      .then(result => { console.log(result); setLineitem_pacing_rate(result?.data) })
      .catch(error => toast.error(error));
  }, [])

  return (
    // <div className="content_outer">
    <div className=" pt-0">
      <Loader showLoader={loadershown} />

      <div className="">
        <label class="lable_title">Details</label>
        <Row className="mb-3">
          <Col lg={3}>
            <div className="form-group select_country_container cmn_modal_field mb-3">
              <label className="lbl_position " htmlFor="lignitemname">
                Line Item Name
              </label>
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="lorem"
                onChange={(e) => setLineitem_name(e.target.value)}
                value={lineitem_name}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className="form-group select_country_container cmn_modal_field mb-3 bg-secondary">
              <label className="lbl_position text-white" htmlFor="inventorytype">
                Inventory Type
              </label>
              <select
                name="inventory_type_id"
                className="form-select bg-secondary text-white"
                onChange={(e) => {
                  setLineitem_inventory(e.target.value);
                  handle_set_inventory_type_id(e);
                }}
                value={lineitem_inventory}
                disabled={true}
              >
                <option>select</option>
                {inventories?.map((all_inventory, index) => {
                  if (all_inventory?.id == 1 || all_inventory?.id == 4) {
                    return (
                      <option value={all_inventory?.id} key={index}>
                        {all_inventory?.description}
                      </option>
                    );
                  }
                })}
              </select>
            </div>
          </Col>
          <Col lg={3}>
            <div className="form-group select_country_container cmn_modal_field mb-3 bg-secondary">
              <label className="lbl_position text-white" htmlFor="format">
                Format
              </label>
              <select
                name="format_id"
                className="form-select bg-secondary text-white"
                onChange={(e) => setLineitem_format(e.target.value)}
                value={lineitem_format}
                disabled={true}
              >
                <option>Select</option>
                {line_item_formats
                  .filter((format) => {
                    // Filter formats based on the selected inventory type (lineitem_inventory)
                    return (
                      // Show all formats when nothing is selected
                      (lineitem_inventory == "1" && format.id <= 9) ||
                      // Show certain formats for inventory type 4
                      (lineitem_inventory == "4" &&
                        (format.id === 12 ||
                          format.id === 13 ||
                          format.id === 18))
                    );
                  })
                  .map((selected_format) => {
                    return (
                      <option
                        value={selected_format.id}
                        key={selected_format.id}
                      >
                        {selected_format.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </Col>
          <Col lg={3}>
            <div className="form-group select_country_container cmn_modal_field mb-3">
              <label className="lbl_position " htmlFor="ofertype">
                Offer Type
              </label>
              <select
                name="inversion_offer_type_id"
                className="form-control"
                onChange={(e) => setLineitem_offer(e.target.value)}
                value={lineitem_offer}
              >
                <option>select</option>
                {lineitem_format == 1 ||
                  lineitem_format == 2 ||
                  lineitem_format == 3 ||
                  lineitem_format == 4 ||
                  lineitem_format == 5 ||
                  lineitem_format == 6 ||
                  lineitem_format == 7 ||
                  lineitem_format == 8 ||
                  lineitem_format == 9 ||
                  lineitem_format == 12 ||
                  lineitem_format == 18
                  ? offer_types?.map((all_offers, index) => {
                    if (all_offers?.id == 5) {
                      return (
                        <option value={all_offers?.id} key={index}>
                          {all_offers?.descripcion}
                        </option>
                      );
                    }
                  })
                  : null}

                {lineitem_format == 13
                  ? offer_types?.map((all_offers, index) => {
                    if (all_offers?.id == 6 || all_offers?.id == 5) {
                      return (
                        <option value={all_offers?.id} key={index}>
                          {all_offers?.descripcion}
                        </option>
                      );
                    }
                  })
                  : null}
              </select>
            </div>
          </Col>
        </Row>
        <label class="lable_title">Pricing and budget</label>
        <Row className="mb-3">
          <Col lg={3}>
            <div className="form-group mb-3 select_country_container cmn_modal_field">
              <label className="lbl_position" htmlFor="startdate">
                Start Date
              </label>
              <DatePicker
                className="form-control"
                dateFormat="dd/MM/yyyy"
                minDate={new Date(props?.startDate)}
                maxDate={new Date(props?.endDate)}
                value={start_date}
                onChange={date =>
                  date < end_date ?
                    setStart_date(date) :
                    (toast.error("Start date must be before end date"))
                }
                selected={start_date}
              />
              {/*<DateRangePicker
                initialSettings={{
                  startDate: new Date(props?.startDate),
                  endDate: new Date(props?.endDate),
                  minDate: new Date(props?.startDate),
                  maxDate: new Date(props?.endDate),
                  locale: {
                    format: "DD/MM/YYYY", // Specify the desired date format here
                  },
                }}
                onCallback={handleCallback}
              >
                <input type="text" className="form-control" />
              </DateRangePicker> */}
            </div>
            <p>
              Actual Start date:{" "}
              {
                <Moment format="DD/MM/YYYY">
                  {lineitem_details?.date_from}
                </Moment>
              }{" "}
            </p>
          </Col>
          <Col lg={3}>
            <div className="form-group mb-3 select_country_container cmn_modal_field">
              <label className="lbl_position" htmlFor="startdate">
                End Date
              </label>
              <DatePicker
                className="form-control"
                dateFormat="dd/MM/yyyy"
                minDate={new Date(props?.startDate)}
                maxDate={new Date(props?.endDate)}
                value={end_date}
                // onChange={date => setEnd_date(date)}
                onChange={date =>
                  date > start_date ?
                    setEnd_date(date) :
                    (toast.error("End date must be after start date"))
                }
                selected={end_date}
              />
            </div>
            <p>
              Actual end date:{" "}
              {<Moment format="DD/MM/YYYY">{lineitem_details?.date_to}</Moment>}{" "}
            </p>
          </Col>
          <Col lg={3}>
            <div className="form-group select_country_container cmn_modal_field mb-3">
              <label className="lbl_position" htmlFor="inventorytype">
                Pacing
              </label>
              <select name="format_id" className="form-select"
                value={selected_pacing}
                onChange={(e) => setSelected_pacing(e.target.value)}
              >
                <option value="">--Select--</option>
                { lineitem_inventory == 4 ? lineitem_pacing?.map((data, index) => {
                  if(data?.id == 1){
                    return (
                      <option key={index} value={data?.id}>{data?.description}</option>
                    )
                  }
                }) :
                 lineitem_pacing?.map((data, index) => {
                    return (
                      <option key={index} value={data?.id}>{data?.description}</option>
                    )
             
                })}
              </select>
            </div>
          </Col>
          <Col lg={3}>
            <div className="form-group select_country_container cmn_modal_field mb-3">
              <label className="lbl_position" htmlFor="inventorytype">
                Pacing Rate
              </label>
              <select name="format_id" className="form-select"
                value={selected_pacing_rate}
                onChange={(e) => setSelected_pacing_rate(e.target.value)}
              >
                <option value="">--Select--</option>
                {lineitem_pacing_rate?.map((data, index) => {
                  return (
                    <option key={index} value={data?.id}>{data?.description}</option>
                  )
                })}
              </select>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={4}>
            <div className="form-group select_country_container cmn_modal_field mb-3">
              <label className="lbl_position " htmlFor="enddate">
                Budget{console.log(props?.deal_currency)}(
                <span style={{ fontSize: "10px" }}>
                  Budget must be between 1 and{" "}
                  {lineitem_details?.inversion_budget +
                    props?.remaining_budget}{" "}
                </span>
                )
              </label>
              <input
                type="number"
                name="inversion_budget"
                className="form-control"
                min={0}
                max={props?.campaign_budget || ""}
                placeholder="Budget"
                onChange={(e) => setLineitem_budget(e.target.value)}
                value={lineitem_budget}
              />
            </div>
          </Col>

          <Col lg={4}>
            <div className="form-group select_country_container cmn_modal_field mb-3">
              <label className="lbl_position " htmlFor="sellrate">
                Bid {props?.deal_currency}
              </label>
              <input
                type="number"
                name="inversion_sell_rate"
                className="form-control"
                onChange={(e) => setLineitem_bid(e.target.value)}
                value={lineitem_bid}
                onBlur={() => handle_get_lineitem_bid_usd_budget_usd()}
              // placeholder="Sell Rate"
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={4}>
            <div className="form-group mb-2">
              <label className=" " htmlFor="netrate">
                Budget USD :
              </label>
              <span>
                {lineitem_budget_usd?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </span>
            </div>
          </Col>
          <Col lg={4}>

            <div className="form-group mb-2">
              <label className=" " htmlFor="netrate">
                Bid USD :
              </label>
              <span>
                {lineitem_bid_usd?.toLocaleString(undefined, {
                  minimumFractionDigits: 4,
                  maximumFractionDigits: 4
                })}
              </span>
            </div>
          </Col>
          <Col lg={4}>
            <div className="form-group  mb-2">
              <label className=" " htmlFor="netrate">
                Estimated impressions :
              </label>
              <span>{lineitem_impression?.toLocaleString()}</span>
            </div>
          </Col>
        </Row>
      </div>
      <label class="lable_title">Targeting</label>
      <div className="">
        <Row className="mb-3">
          {youtube_inventory ? (
            <Col lg={6}>
              <div className="form-group select_country_container cmn_modal_field mb-3">
                <label className="lbl_position " htmlFor="country">
                  Country
                </label>
                <Multiselect
                  options={countries.map((i) => {
                    const name = i?.labelKey ?? "Unknown";
                    return { ...i, name };
                  })}
                  selectedValues={lineitem_countries}
                  onSelect={(e) => {
                    handle_multiselect_countries(e);
                  }}
                  singleSelect={true}
                  displayValue="name"
                  className="react-datepicker-popper"
                />
              </div>
            </Col>
          ) : (
            <Col lg={6}>
              <div className="form-group select_country_container cmn_modal_field mb-3">
                <label className="lbl_position " htmlFor="country">
                  Country
                </label>
                <Multiselect
                  options={countries.map((i) => {
                    const name = i?.labelKey ?? "Unknown";
                    return { ...i, name };
                  })}
                  selectedValues={lineitem_countries}
                  onSelect={(e) => {
                    handle_multiselect_countries(e);
                  }}
                  onRemove={(e) => handle_multiremove_country(e)}
                  displayValue="name"
                  className="react-datepicker-popper"
                />
              </div>
            </Col>
          )}
          <Col lg={6}>
            <div className="form-group select_country_container cmn_modal_field mb-3">
              <label className="lbl_position " htmlFor="devices">
                Devices
              </label>
              <Multiselect
                options={devices.map((i) => {
                  const name = i?.name ?? "Unknown";
                  return { ...i, name };
                })}
                selectedValues={lineitem_device}
                onSelect={(e) => handle_multiselect_devices(e)}
                onRemove={(e) => handle_multiremove_device(e)}
                displayValue="name"
              />
            </div>
          </Col>
        </Row>
        <label class="lable_title">Audience</label>
        <Row>
          <Col lg={4}>
            <div className="form-group select_country_container cmn_modal_field mb-3">
              <label className="lbl_position" htmlFor="country">
                Gender
              </label>
              <select
                name="gender"
                className="form-select"
                onChange={(e) => setLineitem_gender(e.target.value)}
                value={lineitem_gender}
              >
                <option>Select</option>
                {genders?.map((all_gender, index) => {
                  return (
                    <option value={all_gender?.id} key={index}>
                      {all_gender?.description}
                    </option>
                  );
                })}
              </select>
            </div>
          </Col>
          <Col lg={4}>
            <div className="form-group select_country_container cmn_modal_field mb-3 multiselect_bottom">
              <label className="lbl_position" htmlFor="country">
                Age
              </label>
              <Multiselect
                options={age_group.map((i) => {
                  const name = i?.age ?? "Unknown";
                  return { ...i, name };
                })}
                selectedValues={lineitem_age}
                onSelect={(e) => {
                  handle_multiselect_age_range(e);
                }}
                onRemove={(e) => handle_multiremove_age(e)}
                displayValue="name"
              />
            </div>
          </Col>

          <Col lg={4}>
            <div className="form-group select_country_container cmn_modal_field mb-3 multiselect_bottom">
              <label className="lbl_position " htmlFor="Passion Points">
                Passion Points
              </label>
              <Multiselect
                options={passion_points.map((i) => {
                  const name = i?.name ?? "Unknown";
                  return { ...i, name };
                })}
                selectedValues={lineitem_passion_points}
                onSelect={(e) => handle_multiselect_passion_points(e)}
                onRemove={(e) => handle_multiremove_passion_points(e)}
                displayValue="name"
              />
            </div>
          </Col>
          {/* <Col lg={12}>
            <label htmlFor="" className="mb-4 mt-2">Backlist:--</label>
          </Col> */}
        </Row>
      </div>

      <div className="model_btn model_btn_new_style">
        <div>{props?.lineitem_status == 1 && <button className="cancel_cm_btn  delete_cmn_btn delete_pink_btn" onClick={() => handleDeleteConfirm()}>Delete</button>}</div>
        <div>  <button className="cancel_cm_btn" onClick={props?.handleClose}>
          Cancel
        </button>
          {lineitem_budget_usd > 50000 ? (
            <button
              className={
                enable_save_button ? "cmn_btn" : "cmn_btn cancel_cmn ms-2"
              }
              // this below function is used to save the lineitem
              onClick={() =>
                toast.error(
                  "You cannot create or edit a Line Item with a budget usd higher than $50.000"
                )
              }
            >
              Save
            </button>
          ) : (
            <button
              className={
                enable_save_button ? "cmn_btn" : "cmn_btn cancel_cmn ms-2"
              }
              // this below function is used to save the lineitem
              onClick={() => handle_save_lineitem()}
            >
              Save
            </button>
          )}</div>
      </div>
    </div>
    // {/* </div> */}
  );
};

export default NewLineItemSaas;
