import React, { useState, useEffect, useRef } from "react";
import "./campaign.css";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FaChevronRight } from "react-icons/fa";
import { MdOutlineHandshake } from "react-icons/md";
import { BiCalendar } from "react-icons/bi";
import Form from "react-bootstrap/Form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { ImFileText2 } from "react-icons/im";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FiAlertCircle } from 'react-icons/fi';

import {
  BsChevronDown,
  BsChevronLeft,
  BsChevronRight,
  BsChevronBarLeft,
  BsChevronBarRight,
} from "react-icons/bs";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { HiBars3 } from "react-icons/hi2";
import { FiChevronDown } from "react-icons/fi";
import {
  AiOutlineClose,
  AiOutlineArrowRight,
  AiOutlineDoubleLeft,
  AiOutlineRight,
  AiOutlineLeft,
  AiOutlineSearch
} from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  campaignManagerSelector,
  dealdetail,
  deal_currency,
  getowners,
  pipelinefilter,
  clearState,
} from "../../app/features/CampaignManager/CampaignManagerSlice";
import {
  deligateSelector,
  clearDeligateState,
  changeDelegate,
} from "../../app/features/CampaignManager/DelegateSlice";
import {
  campaignfilterStatus,
  filtercampaign,
  updateFilteredCampaign,
  clearmediaState,
  mediaPlanManagerSelector,
  changeCampaignStatus,
} from "../../app/features/CampaignManager/MediaPlanManager";
import {
  CommonSelector,
  updateBreadCrumb,
} from "../../app/features/Common/CommonSlice";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { toast } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import Moment from "react-moment";
import Modal from "react-bootstrap/Modal";
import NewCompaign from "./newCompaign";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Loader from "../Loader";
import Dropdown from "react-bootstrap/Dropdown";
import { CChart } from "@coreui/react-chartjs";
import Spinner from 'react-bootstrap/Spinner';
import {
  LineItemsSelector,
  getDevices,
  getLineItemStatus,
  changeLineItemStatus,
  getCampaign
} from "../../app/features/LineItemManager/LineItemManagerSlice";
import moment from "moment";
import { log } from "video.js";
import EditCampaign from './EditCampaign'
import { upperCaseFirstLetter, lowerCaseAllWordsExceptFirstLetters } from "../../utils";

const Mediaplan = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { dealid, deal_id, currencyCode, status } = location.state
    ? location.state
    : location;

    console.log(dealid,deal_id)

  const [modalShow, setModalShow] = useState(false);
  const [loadershown, setloadershown] = useState(false);
  const [csOwners, setCsOwners] = useState([]);
  const [adopsOwners, setAdopsOwners] = useState([]);
  const [salesOwners, setSalesOwners] = useState([]);
  const [start_Date, setStart_Date] = useState("");
  const [startDateCampaign, setStartDateCampaign] = useState("");
  const [endDateCampaign, setEndDateCampaign] = useState("");
  const [activeTab, setActiveTab] = useState(false);
  const [end_Date, setEnd_Date] = useState("");
  const [currency_code, setCurrency_code] = useState("");
  const [optimizeData, setOptimizeData] = useState([]);
  const [deviceId, setDeviceId] = useState([]);
  const [indexNo, setIndexNo] = useState();
  const [rowPage, setRowPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [filteredCampaignData, setFilteredCampaignData] = useState([]);
  const [valueAcsending, setValueAcsending] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [statusmodalShow, setStatusModalShow] = useState(false);
  const [statusmodalShow1, setStatusModalShow1] = useState(false);
  const [optimizeQuery, setOptimizeQuery] = useState(false);
  const [asc, setAsc] = useState(false);
  const [pageNo, setPageNo] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [page_Count, setPage_count] = useState(0)
  const [current_Page, setCurrent_Page] = useState(1)
  const [status_Id, setStatus_Id] = useState()
  const [campaignSearch, setCampaignSearch] = useState("")
  const [searchCampaignId, setSearchCampaignId] = useState("")
  const [pipelineOption, setPipelineOption] = useState([])
  const [pipelineData, setPipelineData] = useState()
  const [searchValue, setSearchValue] = useState("")
  const [searchSet, setSearch] = useState(false)
  const [tempStatus, setTempStatus] = useState("Status")
  const [startend, setStartend] = useState({ start: "", end: "" });
  const [daterange, setrange] = useState("");
  const pickerref = useRef(null);
  const [modalcampaignShow, setModalCampaignShow] = useState(false)
  const [editCampaignId, setEditCampaignId] = useState()
  const [campaignActualStatus, setCampaignActualStatus] = useState('')
  const [campaign_list, setCampaign_list] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [show_div, setShow_div] = useState(false)
  const {
    filtered_items,
    campaign_detail,
    inserted_lineitem_id,
    isSuccesschangeLineItemStatus
  } = useSelector(LineItemsSelector);
  console.log(campaign_detail, "campaign_detail")
  const [statusmodel, SetStatusModel] = useState({
    messageText: "",
    buttonText: "",
    buttonClass: "",
    campaignId: "",
    campaignStatus: "",
  });

  const [selectedOwners, setSelectedOwner] = useState({
    csowner: "",
    adopsowner: "",
    salesowner: "",
  });
  const [modal_open, setModal_open] = useState(false);
  const { devices, line_item_status } = useSelector(LineItemsSelector);

  const { deal_detail, dealCurrency, isFetching, isSuccess, isError, error } =
    useSelector(campaignManagerSelector);
  console.log(deal_detail, "deal  details")
  const {
    isFetchingChangeDeligate,
    isSuccessChangeDeligate,
    isErrorChangeDeligate,
    errorChangeDeligate,
  } = useSelector(deligateSelector);
  const {
    campaign_status_filter,
    filtered_campaign,
    isFetchingCampaignStatus,
    isSuccessCampaignStatus,
    isErrorCampaignStatus,
    MediaPlanManagerError,
  } = useSelector(mediaPlanManagerSelector);
  const remainingBudget =
    deal_detail?.budgetDeal - deal_detail?.campaignTotalBudget;
  const [filter, setFilter] = useState({
    name: {
      name: "Campaign",
      type: "text",
      values: "",
      shown: true,
      multiselect: false,
    },
    campaignId: {
      name: "ID",
      type: "number",
      values: "",
      shown: true,
      multiselect: false,
    },

    productId: {
      name: "Product",
      type: "text",
      values: "",
      shown: true,
      multiselect: false,
    },
    pipeline: {
      name: "Market",
      type: "dropdown",
      values: [],
      shown: true,
      multiselect: false,
    },
    statusId: {
      name: "Status",
      type: "dropdown",
      values: campaign_status_filter,
      shown: true,
      multiselect: false,
    },
  });
  const [selectedfilter, SetSelectedfilter] = useState({
    name: {
      name: "",
      value: "",
      key: "name",
    },
    campaignId: {
      name: "",
      value: "",
      key: "campaignId",
    },
    productId: {
      name: "",
      value: "",
      key: "productId",
    },
    pipeline: {
      name: "",
      value: [],
      key: "pipeline",
    },
    statusId: {
      name: "",
      value: "",
      key: "statusId",
    },
  });
  const [filtervalue, setFilterValue] = useState({
    name: "",
    campaignId: "",
    productId: "",
    pipeline: [],
    statusId: "",
  });
  useEffect(() => {
    getData();
  }, [deal_id]);

  const getData = () => {
    if (deal_id) {
      dispatch(
        dealdetail({ token: localStorage.getItem("token"), dealId: deal_id })
      );
      dispatch(
        deal_currency({ token: localStorage.getItem("token"), dealId: dealid })
      );
      // dispatch(filtercampaign({ token: localStorage.getItem('token'), deal_id: deal_id, filter: {} }))
    }
  };

  // useEffect(() => {
  //     setFilteredCampaignData(filtered_campaign?.results)
  // }, [filtered_campaign])

  // useEffect(() => {
  //   setloadershown(true);
  //   searchFilter();
  // }, [page]);
  // const filter_submit = () => {
  //   if (page == 1) {
  //     searchFilter();
  //     setloadershown(false);
  //   } else {
  //     setPage(1);
  //     setloadershown(false);
  //   }
  // };

  useEffect(() => { })

  useEffect(() => {
    setStart_Date(deal_detail?.dateFrom);
    setEnd_Date(deal_detail?.dateTo);
    setCurrency_code(currencyCode);
  }, []);
  useEffect(() => {
    dispatch(
      getowners({ token: localStorage.getItem("token"), ownergroup: 2 })
    ).then((data) => {
      setCsOwners(data.payload.data);
    });
    dispatch(
      getowners({ token: localStorage.getItem("token"), ownergroup: 3 })
    ).then((data) => {
      setAdopsOwners(data.payload.data);
    });
    dispatch(
      getowners({ token: localStorage.getItem("token"), ownergroup: 1 })
    ).then((data) => {
      setSalesOwners(data.payload.data);
    });
    dispatch(pipelinefilter({ token: localStorage.getItem("token") }))
      .then((result) => {
        setPipelineOption(result?.payload?.data)
      })
      .catch((err) => { });
    dispatch(campaignfilterStatus({ token: localStorage.getItem("token") }));
  }, []);

  useEffect(() => {
    if (devices?.length == 0) {
      dispatch(getDevices({ token: localStorage.getItem("token") }));
    }

    dispatch(getLineItemStatus({ token: localStorage.getItem("token") }))
      .then((result) => {
        const statusoptions = { ...filter };
        statusoptions.statusId.values = result?.payload?.data;
      })
      .catch((err) => { });
  }, []);

  useEffect(() => {
    if (isSuccessChangeDeligate) {
      dispatch(
        dealdetail({ token: localStorage.getItem("token"), dealId: deal_id })
      );
    }
    if (isErrorChangeDeligate) {
      toast.error(errorChangeDeligate);
    }
    dispatch(clearDeligateState());
  }, [isErrorChangeDeligate, isSuccessChangeDeligate]);

  const getReloadData = () => {
    dispatch(
      dealdetail({ token: localStorage.getItem("token"), dealId: deal_id })
    );
  }

  useEffect(() => { }, []);

  useEffect(() => {
    if (csOwners?.length > 0) {
      let selectedcsindex = csOwners.findIndex(
        (x) => x.email == deal_detail?.amOwner
      );
      if (selectedcsindex != -1) {
        setSelectedOwner((prevState) => ({
          ...prevState,
          csowner:
            csOwners[selectedcsindex].first_name.charAt(0) +
            csOwners[selectedcsindex].last_name.charAt(0),
        }));
      }
    }

    if (adopsOwners?.length > 0) {
      let selectedadopsindex = adopsOwners.findIndex(
        (x) => x.email == deal_detail?.adopsOwner
      );
      if (selectedadopsindex != -1) {
        setSelectedOwner((prevState) => ({
          ...prevState,
          adopsowner:
            adopsOwners[selectedadopsindex].first_name.charAt(0) +
            adopsOwners[selectedadopsindex].last_name.charAt(0),
        }));
      }
    }

    if (salesOwners?.length > 0) {
      let selectedsalesindex = salesOwners.findIndex(
        (x) => x.email == deal_detail?.salesOwner
      );
      if (selectedsalesindex != -1) {
        setSelectedOwner((prevState) => ({
          ...prevState,
          salesowner:
            salesOwners[selectedsalesindex].first_name.charAt(0) +
            salesOwners[selectedsalesindex].last_name.charAt(0),
        }));
      }
    }
    if (Object.keys(deal_detail)?.length > 0) {
      dispatch(
        updateBreadCrumb([
          {
            name: deal_detail?.advertiser,
            params: { dealid: dealid, deal_id: deal_id },
            path: "campaign/mediaplan",
          },
        ])
      );
    }
  }, [csOwners, adopsOwners, salesOwners, deal_detail]);

  // useEffect(() => {
  //   setFilteredCampaignData(filtered_campaign?.results);
  // }, [filtered_campaign?.results]);

  const new_campaign = () => {
    navigation("/campaign/newCompaign", {
      state: {
        deal_id: deal_id,
        dealid: dealid,
        start_date: deal_detail?.createdate,
        end_date: deal_detail?.closedate,
      },
    });
  };


  useEffect(() => {
    onRefresh();
  }, [campaign_detail]);

  const onRefresh = () => {
    return campaign_detail;
    // alert("ngkenfgnghnre")
  };

  const changeowner = (type, email) => {
    dispatch(
      changeDelegate({
        token: localStorage.getItem("token"),
        owner: type,
        email: email,
        dealId: dealid,
      })
    );
  };

  const removefilter = (key, index = null) => {
    if (index == null) {
      setFilterValue((prev_val) => ({ ...prev_val, [key]: "" }));
      SetSelectedfilter((prev_val) => ({
        ...prev_val,
        [key]: {
          name: "",
          value: "",
          key: key,
        },
      }));
    }
    if (index != null) {
      selectedfilter[key].value.splice(index, 1);
      filtervalue[key].splice(index, 1);
      setFilterValue((prev_val) => ({ ...prev_val, [key]: filtervalue[key] }));
      SetSelectedfilter((prev_val) => ({
        ...prev_val,
        [key]: { ...selectedfilter[key], value: selectedfilter[key].value },
      }));
    }
  };

  const reset_filter = () => {
    let posted_array = Object.assign({}, filtervalue);
    Object.keys(posted_array).forEach((key) => {
      if (
        posted_array[key] !== "" ||
        (typeof posted_array[key] === "object" && posted_array[key]?.length > 0)
      ) {
        if (typeof posted_array[key] === "object") {
          posted_array[key] = [];
          SetSelectedfilter((prev_val) => ({
            ...prev_val,
            [key]: { ...selectedfilter[key], value: [] },
          }));
        } else {
          posted_array[key] = "";
          SetSelectedfilter((prev_val) => ({
            ...prev_val,
            [key]: { ...selectedfilter[key], value: "" },
          }));
        }
      }
    });
    setFilterValue(posted_array);
  };

  var i = 0;

  const setInput = (e, key) => {
    setFilterValue((prev_val) => ({ ...prev_val, [key]: e.target.value }));
  };
  const applyinput = (e, key, name = null) => {
    if (filtervalue[key] != "") {
      SetSelectedfilter((prev_val) => ({
        ...prev_val,
        [key]: {
          name: name,
          value: filtervalue[key],
          key: key,
        },
      }));
    }
  };
  const managefilter = (values) => {
    switch (values.name) {
      case "Market":
        if (
          filtervalue.pipeline.findIndex((element) => element == values.id) ==
          -1
        ) {
          setFilterValue((prev_val) => ({
            ...prev_val,
            pipeline: [...filtervalue.pipeline, values.id],
          }));
          SetSelectedfilter((prev_val) => ({
            ...prev_val,
            pipeline: {
              name: "Market",
              value: [...selectedfilter.pipeline.value, values],
              key: "pipeline",
            },
          }));
        }
        break;

      case "Status":
        setFilterValue((prev_val) => ({ ...prev_val, statusId: values.id }));
        SetSelectedfilter((prev_val) => ({
          ...prev_val,
          statusId: {
            name: "Status",
            value: values.value,
            key: "statusId",
          },
        }));
        break;
    }
  };

  const searchFilter = () => {
    setloadershown(true);
    let posted_array = Object.assign({}, filtervalue);
    Object.keys(posted_array).forEach((key) => {
      if (
        posted_array[key] === "" ||
        (typeof posted_array[key] === "object" &&
          posted_array[key]?.length == 0)
      ) {
        delete posted_array[key];
      }
    });

    dispatch(
      filtercampaign({
        token: localStorage.getItem("token"),
        deal_id: deal_id,
        filter: posted_array,
        page: page,
        pageSize: 10,
      })
    ).then((result) => setloadershown(false));
  };

  const gotolineitems = (campaignId, currency_code, remainingBudget, campaignStatus) => {
    navigation("/campaign/lineitems", {
      state: {
        campaignId: campaignId,
        startdate: deal_detail?.dateFrom,
        enddate: deal_detail?.dateTo,
        deal_id: deal_id,
        dealid: dealid,
        advertiser: deal_detail?.advertiser,
        clientId: deal_detail?.advertiserId,
        total_budget: deal_detail?.budgetDeal,
        sum_budget: deal_detail?.campaignTotalBudget,
        currency_code,
        remainingBudget: remainingBudget,
        campaignStatus: campaignStatus,
      },
    });
  };
  // const handlePageClick = (event) => {
  //   let newpage = event.selected + 1;
  //   setPage(newpage);
  // };

  const StatusPopup = (id, status, name) => {
    if (status === 1) {
      toast.error("sorry the status cannot change to Draft");
    } else {
      switch (name) {
        case "ACTIVE":
          SetStatusModel({
            ...statusmodel, // Copy the old fields
            messageText: " Are you sure you want to Active this campaign",
            buttonText: "Confirm",
            buttonClass: "cmn_btn ms-2  ",
            campaignId: id,
            campaignStatus: status,
          });
          setStatusModalShow(true);
          break;
        case "INACTIVE":
          SetStatusModel({
            ...statusmodel, // Copy the old fields
            campaignId: id,
            campaignStatus: status,
          });
          dispatch(
            changeCampaignStatus({
              token: localStorage.getItem("token"),
              campaign_id: id,
              status_id: status,
            })
          ).then((result) => {
            searchFilter();
          });
          break;
        case "DRAFT":
          SetStatusModel({
            ...statusmodel, // Copy the old fields
            messageText: "Are you sure you want to put as Draft",
            campaignId: id,
            campaignStatus: status,
          });
          dispatch(
            changeCampaignStatus({
              token: localStorage.getItem("token"),
              campaign_id: id,
              status_id: status,
            })
          ).then((result) => {
            searchFilter();
          });
          break;
        case "PAUSE":
          SetStatusModel({
            ...statusmodel, // Copy the old fields
            messageText: "Are you sure you want to Pause this campaign",
            buttonText: "Confirm",
            buttonClass: "cmn_btn ms-2 ",
            campaignId: id,
            campaignStatus: status,
          });
          setStatusModalShow(true);
          break;
      }
    }
  };

  const updateStatus = () => {
    setloadershown(true);
    dispatch(
      changeCampaignStatus({
        token: localStorage.getItem("token"),
        campaign_id: statusmodel.campaignId,
        status_id: statusmodel.campaignStatus,
      })
    ).then((result) => {
      getCampaignInfo()
      searchFilter();
      setStatusModalShow(false);
      setloadershown(false);

    });
  };
  const resetstatus = () => {
    SetStatusModel({
      ...statusmodel, // Copy the old fields
      messageText: "",
      buttonText: "",
      buttonClass: "",
      campaignId: "",
      campaignStatus: "",
    });
  };

  // useEffect(() => {
  //   if (deal_detail?.dealId && optimizeQuery) {
  //     getOptimizeData();
  //   }
  // }, [deal_detail.dealId, rowPage, size, optimizeQuery]);

  // const getOptimizeData = async () => {
  //   setloadershown(true);
  //   const response = await fetch(
  //     `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/get_optimize_data/${deal_detail.dealId}/${rowPage}/${size}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
  //       },
  //     }
  //   );
  //   const result = await response.json();
  //   if (result === []) {
  //     setloadershown(true);
  //   } else {
  //     setOptimizeData(result?.data?.results);
  //     setPageCount(result?.data?.page_count);
  //     setloadershown(false);
  //   }
  // };

  const StatusPopup1 = (id, status, name) => {
    if (status === 1) {
      toast.error("sorry the value cannot change to Draft");
    } else {
      switch (name) {
        case "ACTIVE":
          dispatch(
            changeLineItemStatus({
              token: localStorage.getItem("token"),
              lineitem_id: id,
              status_id: status,
            })
          ).then((result) => {
            searchFilter();
          });
          break;
        case "DRAFT":
          SetStatusModel({
            ...statusmodel, // Copy the old fields
            messageText: "Do you want to confirm the status change for the selected IDs",
            buttonText: "Confirm",
            buttonClass: "cmn_btn ms-2  ",
            lineItemId: id,
            lineItemStatus: status,
          });
          setStatusModalShow1(true);
          break;
        case "INACTIVE":
          dispatch(
            changeLineItemStatus({
              token: localStorage.getItem("token"),
              lineitem_id: id,
              status_id: status,
            })
          ).then((result) => {
            searchFilter();
          });
          break;
        case "PAUSE":
          SetStatusModel({
            ...statusmodel, // Copy the old fields
            messageText: "Are you sure you want to Pause this campaign",
            buttonText: "Confirm",
            buttonClass: "cmn_btn ms-2 ",
            lineItemId: id,
            lineItemStatus: status,
          });
          setStatusModalShow1(true);
          // getOptimizeData()
          break;
      }
    }
  };

  const updateStatus1 = () => {
    setloadershown(true);
    dispatch(
      changeLineItemStatus({
        token: localStorage.getItem("token"),
        lineitem_id: statusmodel.lineItemId,
        status_id: statusmodel.lineItemStatus,
      })
    ).then((result) => {
      setStatusModalShow1(false);
      getCampaignInfo()
      // getOptimizeData()
    });
  };

  const handleDevice = (id, e, line_id) => {
    e.stopPropagation();
    if (!deviceId.includes(id)) {
      setDeviceId((prevItems) => [...prevItems, id]);
    }
  };

  // const handleUpdtaeDevices = async (id, e) => {
  //   setloadershown(true);
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append(
  //     "Authorization",
  //     "Bearer " + localStorage.getItem("token")
  //   );

  //   var raw = JSON.stringify({
  //     lineitem_devices: deviceId,
  //   });

  //   var requestOptions = {
  //     method: "PUT",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   const response = await fetch(
  //     `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/update-Device/${id}`,
  //     requestOptions
  //   );
  //   const result = await response.json();
  //   if (result) {
  //     getOptimizeData();
  //   }
  // };

  const handleUpdtaeMargin = (id, e) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var raw = JSON.stringify({
      inversion_netMargin: e.target.value,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/update-Device/${id}`,
      requestOptions
    )
      // .then(result => getOptimizeData())
      .catch((error) => toast.error(error));
  };

  const handleTabClick = () => {
    setOptimizeQuery(true);
  };

  const handlesort = (field) => {
    setloadershown(true);
    setAsc(!asc);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var raw = JSON.stringify({
      "name": campaignSearch ? campaignSearch : null,
      "campaignId": searchCampaignId ? searchCampaignId : null,
      "productId": null,
      "statusId": status_Id ? status_Id : null,
      "pipeline": pipelineData ? pipelineData : null,
      FieldName: field,
      Order: asc ? "asc" : "desc",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/campaigns/${deal_id}/${pageNo}/${pageLimit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setFilteredCampaignData(result?.data?.results);
        setloadershown(false);
      })
      .catch((error) => {
        toast.error("internal error occured");
        setloadershown(false);
      });
  };

  useEffect(() => {
    getCampaignInfo()
  }, [])

  const getCampaignInfo = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "name": campaignSearch ? campaignSearch : null,
      "campaignId": searchCampaignId ? searchCampaignId : null,
      "productId": null,
      "statusId": status_Id ? status_Id : null,
      "pipeline": pipelineData ? pipelineData : null
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/campaigns/${deal_id}/${pageNo}/${pageLimit}`, requestOptions)
      .then(response => response.json())
      .then(result => { setFilteredCampaignData(result?.data?.results); setPage_count(result?.data?.page_count); setCurrent_Page(result?.data?.current_page); setloadershown(false) })
      .catch(error => toast.error('internal server error occured'));
  }

  useEffect(() => {
    getCampaignPage()
  }, [pageNo, pageLimit, status_Id, searchCampaignId, campaignSearch, pipelineData, startend])

  const getCampaignPage = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "name": campaignSearch ? campaignSearch : null,
      "campaignId": searchCampaignId ? searchCampaignId : null,
      "productId": null,
      "statusId": status_Id ? status_Id : null,
      "pipeline": pipelineData ? pipelineData : null,
      "date_from": startend.start ? startend.start : null,
      "date_to": startend.end ? startend.end : null,

    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/campaigns/${deal_id}/${pageNo}/${pageLimit}`, requestOptions)
      .then(response => response.json())
      .then(result => { setFilteredCampaignData(result?.data?.results); setPage_count(result?.data?.page_count); setCurrent_Page(result?.data?.current_page) })
      .catch(error => toast.error('internal server error occured'));
  }

  const percentage = (deal_detail?.campaignTotalBudget / deal_detail?.budgetDeal) * 100

  const handleIdAndCampaign = (e) => {
    if (/[a-zA-Z]/.test(e.target.value)) {
    } else {
      setSearchCampaignId(e.target.value)
    }
  }

  const handlePipe = (e) => {
    let data = []
    data.push(e.target.value)
    setPipelineData(data)
    setStatus_Id()
  }

  const handleApply = (event, picker) => {
    setSearch(true)
    picker.element.val(
      picker.startDate.format("DD/MM/YYYY") +
      " - " +
      picker.endDate.format("DD/MM/YYYY")
    );
    setStartend({
      start: picker.startDate.format("YYYY-MM-DD"),
      end: picker.endDate.format("YYYY-MM-DD"),
    });
    setrange(
      picker.startDate.format("YYYY-MM-DD") +
      " - " +
      picker.endDate.format("YYYY-MM-DD")
    );
  };

  const handleCancel = (event, picker) => {
    picker.element.val("");
  };

  const permission = JSON.parse(localStorage.getItem('userdata'));

  const handle_search_campaign_name = (e) => {
    if (!/[a-zA-Z]/.test(e.target.value)) {
      setShow_div(false);
      setCampaign_list([]);
    }
    if (/[a-zA-Z]/.test(e.target.value)) {
      setSpinner(true)
      setShow_div(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      var raw = JSON.stringify({
        "name": e.target.value,
        "kite_deal_id": deal_id
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManager/filter-campaign`, requestOptions)
        .then(response => response.json())
        .then(result => { console.log(result); setCampaign_list(result?.data); setSpinner(false) })
        .catch(error => console.log('error', error));
    }
  }

  return (
    <div className="content_outer">
      <Loader showLoader={loadershown} />
      <div className="cmn_header_top">
        <h3>

          <img src="/assets/images/chevron_left.svg" onClick={() => navigation(-1)} />
          Campaign Manager</h3>
        <div className="cmn_header__search position-relative">
          <AiOutlineSearch size={24} />	<input type="text" placeholder="Search by Id or Campaign" value={searchValue} onBlur={(e) => handleIdAndCampaign(e)} onChange={(e) => { handle_search_campaign_name(e); setSearchValue(e.target.value); setSearch(true) }} onClick={() => { setShow_div(false); setCampaign_list([]) }} />
          {show_div && <div className="search_dropown">
            {spinner &&
              <div className="p-3 text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>

              </div>
            }
            <ul>
              {
                campaign_list?.map((data, index) => {
                  return (
                    <li key={index} onClick={() => { setCampaignSearch(data?.name); setShow_div(false) }}>{data?.name}</li>
                  )
                })
              }
              {/* <li>deepak</li>
									<li>hankuuu</li> */}
            </ul>
          </div>}
        </div>


        {permission?.Permissions[5] && <div className="">
          {deal_detail?.campaignTotalBudget !=
            deal_detail?.budgetDeal &&
            deal_detail.campaignTotalBudget <
            deal_detail.budgetDeal ? (
            <button
              className="cmn_btn ms-0"
              onClick={() => {
                setModalShow(true);
                reset_filter();
              }}
            >
              <FontAwesomeIcon className="me-2" icon={faPlus} />
              Add New Campaign{" "}
            </button>
          ) : (
            <button
              className="cmn_btn ms-0"
              onClick={() => {
                setModal_open(true);
              }}
            >
              {" "}
              <FontAwesomeIcon className="me-2" icon={faPlus} />
              Add New Campaign{" "}
            </button>
          )}
        </div>}

      </div>
      <div className="content pt-0">
        <div className="App">
          {/* <div className="header_nav">
            <button onClick={() => navigation(-1)}>
              {" "}
              <AiOutlineLeft />
            </button>
            <div className="bredcrum">
              <p>Media Plan Details</p>
            </div>
          </div> */}
          <div className="mediaplan_details kite_space_cmn">
            <div className="title__active">
              <h3>{deal_detail?.campaign}</h3>
              <div className="status_badge">
                {status == 1 && <p><AiOutlineCheckCircle size={24} className="me-2 primary" />New</p>}
                {status == 0 && <p><AiOutlineCheckCircle size={24} className="me-2" />Active</p>}
                {status == 2 && <p><AiOutlineCheckCircle size={24} className="me-2 danger" />InActive</p>}
              </div>
            </div>
            <Row>
              <Col lg={7} xxl={8}>
                <div className="advert_tabel">
                  <div className="media_plan_info_list">
                    <ul className="header__media">
                      <li>
                        <MdOutlineHandshake size={24} />  <strong>Deal Id</strong><p> {deal_detail?.dealId}</p>
                      </li>
                      <li>
                        <BiCalendar size={24} />  <strong>Start Date</strong>
                        <p>
                          {" "}
                          <Moment format="DD/MM/YYYY">
                            {deal_detail?.dateFrom}
                          </Moment>
                        </p>
                      </li>
                      <li className="block_divider"><span></span></li>
                      <li>
                        <strong>End Date</strong>
                        <p>
                          {" "}
                          <Moment format="DD/MM/YYYY">
                            {deal_detail?.dateTo}
                          </Moment>
                        </p>
                      </li>
                      <li className="block_divider"><span></span></li>
                      <li><strong>Flight days</strong>

                        <p>{moment(deal_detail?.dateTo).diff(
                          moment(deal_detail?.dateFrom),
                          "days"
                        )}</p>
                      </li>
                      <li className="block_divider"><span></span></li>
                      <li><strong>Remaining days</strong><p>{Date.now() > moment(deal_detail?.dateFrom) ? moment(deal_detail?.dateTo).diff(
                        moment(),
                        "days"
                      ) : moment(deal_detail?.dateTo).diff(
                        moment(deal_detail?.dateFrom),
                        "days"
                      )}</p></li>
                    </ul>
                    <ul>

                      {/* <li>
                      <strong>Deal name</strong><p> {deal_detail?.campaign}</p>
                    </li> */}

                      <li>
                        <strong>Advertiser</strong>
                        <p> {deal_detail?.advertiser}</p>
                      </li>
                      {/* <li>
                                            <strong>Budget</strong>
                                            <p> {parseFloat(deal_detail?.budgetDeal).toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                                        </li> */}
                      <li>
                        <strong>Agency</strong>
                        <p>
                          {" "}
                          {deal_detail?.agencyName
                            ? deal_detail?.agencyName
                            : "NA"}
                        </p>
                      </li>


                      <li>
                        <strong>Market</strong>
                        <p> {deal_detail?.pipelineNavigation?.label}</p>
                      </li>
                      <li>
                        <strong>Currency</strong>
                        <p> {currencyCode ? currencyCode : "NA"}</p>
                      </li>
                      {/* <li>
                                    <strong>Budget used</strong> 
                                    {parseFloat(deal_detail?.campaignTotalBudget).toLocaleString(undefined, { minimumFractionDigits: 2 })}</li> */}
                      {!permission?.Permissions[22] && <li>
                        <strong>Fee</strong>
                        <p> {deal_detail?.kickback * 100 + "%"}</p>
                      </li>}
                      {/* <li>
                      <strong>Status</strong>
                      {(deal_detail?.status == 1 ||
                        deal_detail?.status == 3) && (
                          <>
                            {deal_detail?.isNew == "1" && <p>New</p>}
                            {deal_detail?.isNew == "0" && <p>Active</p>}
                          </>
                        )}
                      {deal_detail?.status == 2 && <p>Inactive</p>}
                    </li> */}
                      <li>
                        <strong>Customer IO</strong>
                        <p style={{ color: "#4f64d9" }}>
                          <a href={deal_detail?.customerIo} target="blank">
                            Link
                          </a>{" "}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={5} xxl={4}>
                <div>
                  <ul className="owners">
                    <li>
                      Owner
                    </li>
                    <li className="owner_slect">
                      <label htmlFor="">  Accounts</label>
                      {/* <label htmlFor="">Account</label>
                      <select name="" id="" value={selectedOwners.csowner} onChange={(e) => changeowner("cs", e.target.value)}>
                        {csOwners?.map((csowner, index) => {
                          return (
                            <>
                              <option value={csowner?.email}>{csowner?.first_name} {csowner?.last_name}</option>
                            </>
                          )
                        })}
                      </select> */}
                      <Dropdown className="w-100">
                        <Dropdown.Toggle
                          id="dropdown-autoclose-true"
                          title="CS Owner"
                        >
                          {selectedOwners.csowner}
                          <FiChevronDown size={16} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {csOwners?.map((csowner, index) => {
                            return (
                              <Dropdown.Item
                                onClick={() => {
                                  changeowner("cs", csowner?.email);
                                }}
                              >
                                {csowner?.first_name} {csowner?.last_name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>


                    </li>
                    <li className="owner_slect">
                      <label htmlFor="">Adops</label>
                      {/* <select name="" id="" onChange={(e) => changeowner("adops", e.target.value)}>
                        {adopsOwners?.map((adopsowner, index) => {
                          return (
                            <>
                              <option value={adopsowner?.email}>{adopsowner?.first_name}{" "}
                                {adopsowner?.last_name}</option>
                            </>
                          )
                        })}
                      </select> */}
                      <Dropdown className="w-100">
                        <Dropdown.Toggle
                          id="dropdown-autoclose-true1"
                          title="Adops Owner"
                        >

                          {selectedOwners.adopsowner}   <FiChevronDown size={16} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {adopsOwners?.map((adopsowner, index) => {
                            return (
                              <Dropdown.Item
                                onClick={() => {
                                  changeowner("adops", adopsowner?.email);
                                }}
                              >
                                {adopsowner?.first_name}{" "}
                                {adopsowner?.last_name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                    <li className="owner_slect">
                      <label htmlFor="">Sales</label>
                      {/* <select name="" id="" onChange={(e) => changeowner("sales", e.target.value)}>
                        {salesOwners?.map((salesowner, index) => {
                          return (
                            <>
                              <option value={salesowner?.email}>{salesowner?.first_name}{" "}
                                {salesowner?.last_name}</option>

                            </>
                          );
                        })}
                      </select> */}
                      <Dropdown className="w-100">
                        <Dropdown.Toggle
                          id="dropdown-autoclose-true2"
                          title="Sales Owner"
                        >
                          {selectedOwners.salesowner}   <FiChevronDown size={16} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {salesOwners?.map((salesowner, index) => {
                            return (
                              <Dropdown.Item
                                onClick={() => {
                                  changeowner("sales", salesowner?.email);
                                }}
                              >
                                {salesowner?.first_name}{" "}
                                {salesowner?.last_name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>

                  </ul>
                  <ul className="budget">
                    <li>
                      Budget
                    </li>
                    <li className="progress_result">
                      <div>

                        <ProgressBar className="bar_color w-100" now={percentage} />
                      </div>
                      <p>Total: <strong> {deal_detail?.budgetDeal?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}</strong></p>
                    </li>
                    <li className="total_used">
                      <div className="totalbuget">
                        <div className="dot_circle">
                        </div>
                        <p>Used : <strong>{deal_detail?.campaignTotalBudget?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}</strong></p>
                      </div>
                      <div className="totalbuget">
                        <div className="dot_circle">
                        </div>
                        <p>Remaining :<strong>{(deal_detail?.budgetDeal -
                          deal_detail?.campaignTotalBudget)?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}</strong></p>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <div className="donut_chart">
                  <CChart
                    type="doughnut"
                    data={{
                      datasets: [
                        {
                          backgroundColor: ["#41B883", "#E46651", "#00D8FF"],
                          data: [
                            deal_detail?.budgetDeal -
                            deal_detail?.campaignTotalBudget,
                            deal_detail?.campaignTotalBudget,
                          ],
                        },
                      ],
                      labels: ["Remaining Budget", "Budget Used"],
                      // 'Total Budget'
                      // deal_detail?.budgetDeal,
                    }}
                  />
                  <p className="text-start">
                    <strong>Total Budget :</strong>{" "}
                    {deal_detail?.budgetDeal?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </p>
                </div> */}
              </Col>
            </Row>
          </div>
        </div>
        {/* filter */}
        <div className="">
          <div className="new_compaign">
            <div className="mediaplan__tabs single_tab">
              <div className="level_spacing _border border-bottom-0 border-top-0 mb-0 pt-0">
                <Tabs
                  defaultActiveKey="Setup"
                  id="uncontrolled-tab-example"
                  // className="tabs_outer_wrapper"
                  className="single_tab"
                  onSelect={handleTabClick}
                >
                  <Tab eventKey="Setup">
                    {/* <div className="media_header mb-0">
                      <h2 className="cmn_title">Media Plan Details</h2>
                      <div className="newcampaign me-2">
                        {deal_detail?.campaignTotalBudget !=
                          deal_detail?.budgetDeal &&
                          deal_detail.campaignTotalBudget <
                          deal_detail.budgetDeal ? (
                          <button
                            className="cmn_btn ms-0"
                            onClick={() => {
                              setModalShow(true);
                              reset_filter();
                            }}
                          >
                            Add New Campaign{" "}
                            <FontAwesomeIcon className="ms-2" icon={faPlus} />
                          </button>
                        ) : (
                          <button
                            className="cmn_btn ms-0"
                            onClick={() => {
                              setModal_open(true);
                            }}
                          >
                            {" "}
                            Add New Campaign{" "}
                            <FontAwesomeIcon className="ms-2" icon={faPlus} />
                          </button>
                        )}
                      </div>
                      <div className="media_dropowns user_menus">
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-autoclose-true"
                            title="CS Owner"
                          >
                            <img src="/assets/images/user.png" alt="" />
                            {selectedOwners.csowner}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {csOwners?.map((csowner, index) => {
                              return (
                                <Dropdown.Item
                                  onClick={() => {
                                    changeowner("cs", csowner?.email);
                                  }}
                                >
                                  {csowner?.first_name} {csowner?.last_name}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-autoclose-true1"
                            title="Adops Owner"
                          >
                            <img src="/assets/images/user.png" alt="" />
                            {selectedOwners.adopsowner}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {adopsOwners?.map((adopsowner, index) => {
                              return (
                                <Dropdown.Item
                                  onClick={() => {
                                    changeowner("adops", adopsowner?.email);
                                  }}
                                >
                                  {adopsowner?.first_name}{" "}
                                  {adopsowner?.last_name}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-autoclose-true2"
                            title="Sales Owner"
                          >
                            <img src="/assets/images/user.png" alt="" />
                            {selectedOwners.salesowner}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {salesOwners?.map((salesowner, index) => {
                              return (
                                <Dropdown.Item
                                  onClick={() => {
                                    changeowner("sales", salesowner?.email);
                                  }}
                                >
                                  {salesowner?.first_name}{" "}
                                  {salesowner?.last_name}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div> */}
                    <div className="campiagn_outer">
                      {/* <h3 className="filter_title">Filter</h3> */}
                      <div className="cmn__filters without_filter_head filter_menu campiagn_filter">
                        <ul>
                          <li><img src="/assets/images/filter_list.svg" /></li>
                          {/* <li>
                            <select name="" class="form-select" id="" onChange={(e) => { handlePipe(e); setPipelineData() }}>
                              <option value="market">Market</option>
                              {
                                pipelineOption?.map((option, i) => {
                                  if (
                                    option?.pipelineId === "c154cd17-a947-4f80-a702-a369c6c8d2c4" ||
                                    option?.pipelineId === "9028d3f5-7669-414d-9954-9b3de9656591" ||
                                    option?.pipelineId === "20249197" ||
                                    option?.pipelineId === "03ca6bf5-789f-4ced-ae3c-556ccf4cab80" ||
                                    option?.pipelineId === "4837244"
                                  ) {
                                    return (
                                      <>
                                        <option value={option?.pipelineId}>{option?.label}</option>
                                      </>
                                    )
                                  }
                                })
                              }
                            </select>
                          </li> */}

                          <li>
                            {/* <select name="" class="form-select" id="" onChange={(e) => {setStatus_Id(e.target.value);setSearch(true)}}>
                              <option >status</option>
                              {campaign_status_filter?.map((status_id, i) => {
                                return (
                                  <>
                                    <option value={status_id?.id}>{status_id?.description}</option>
                                  </>
                                )

                              })}

                            </select> */}
                            <Dropdown className="owner_filter">
                              <Dropdown.Toggle id="dropdown-basic">
                                {tempStatus} <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {campaign_status_filter?.map((status_id, i) => {
                                  return (
                                    <>
                                      <Dropdown.Item key={i} onClick={() => { setStatus_Id(status_id?.id); setSearch(true); setTempStatus(upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(status_id?.description))) }}>{upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(status_id?.description))}</Dropdown.Item>
                                    </>
                                  )
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                          <li>

                            <Form.Group className=" owner_filter mt-0 round_select" controlId="formBasicEmail">
                              {/* <Form.Label className="center_label lbl_position dropdown-basic">Start Date</Form.Label> */}
                              <DateRangePicker
                                initialSettings={{
                                  autoUpdateInput: false,
                                  showDropdowns: true,
                                }}
                                onApply={handleApply}
                                onCancel={handleCancel}
                                ref={pickerref}
                              >
                                {/* <Form.Control
                                  type="text"
                                  placeholder="yyyy/mm/dd"  // Changed Placeholder to placeholder

                                  value={startend.start + " " + startend.end}  // Assuming `startend.start` is the selected date
                                /> */}
                                <input placeholder="yyyy/mm/dd" value={startend.start && startend.end ? startend.start + " " + startend.end : "Start Date"} className="form-control" />
                              </DateRangePicker>

                            </Form.Group>

                          </li>
                          <button className={!searchSet ? "clear__btn" : "clear__btn1"} onClick={() => {
                            setStatus_Id();
                            setCampaignSearch();
                            setSearchCampaignId();
                            getCampaignInfo();
                            setSearchValue("");
                            setSearch(false);
                            setTempStatus("Status")
                            setStartend({
                              start: "",
                              end: ""
                            })

                          }}>Clear</button>


                        </ul>
                      </div>
                      <div className="campiagn_filter d-none">
                        {Object.keys(selectedfilter)?.map((value, index) => {
                          return (
                            <>
                              {selectedfilter[value]?.value != "" &&
                                typeof selectedfilter[value]?.value ==
                                "string" && (
                                  <span className="show_filter" key={index}>
                                    {selectedfilter[value]?.name}:{" "}
                                    {selectedfilter[value]?.value}{" "}
                                    <AiOutlineClose
                                      onClick={() =>
                                        removefilter(selectedfilter[value]?.key)
                                      }
                                    />{" "}
                                  </span>
                                )}
                              {typeof selectedfilter[value]?.value ==
                                "object" &&
                                selectedfilter[value]?.value?.length > 0 &&
                                selectedfilter[value]?.value.map(
                                  (element, index) => {
                                    return (
                                      <span className="show_filter" key={index}>
                                        {selectedfilter[value]?.name}:{" "}
                                        {element?.value}{" "}
                                        <AiOutlineClose
                                          onClick={() =>
                                            removefilter(
                                              selectedfilter[value]?.key,
                                              index
                                            )
                                          }
                                        />{" "}
                                      </span>
                                    );
                                  }
                                )}
                            </>
                          );
                        })}
                        <div className="filter_bg">
                          <div className="add_filter">
                            <div className="filter_menu">
                              <Dropdown className="advert">
                                <Dropdown.Toggle
                                  id="dropdown-autoclose-true3"
                                  className="filter_btn"
                                >
                                  Add Filter
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="filter_menu">
                                  <ul>
                                    {Object.keys(filter)?.map(
                                      (ddown, index) => {
                                        return (
                                          <li key={index}>
                                            <Dropdown
                                              className={`child${index}`}
                                            >
                                              <Dropdown.Toggle id="dropdown-autoclose-true4">
                                                <span>
                                                  {" "}
                                                  {filter[ddown].name}
                                                </span>{" "}
                                                <FaChevronRight />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu className="child_filters">
                                                {(filter[ddown].type ==
                                                  "text" ||
                                                  filter[ddown].type ==
                                                  "number") && (
                                                    <div>
                                                      <p>
                                                        <b>Contains</b>
                                                      </p>
                                                      <div>
                                                        <label htmlFor="">
                                                          Any of the following
                                                          values
                                                        </label>
                                                        <input
                                                          type={
                                                            filter[ddown].type
                                                          }
                                                          className="form-control mt-2"
                                                          onChange={(e) =>
                                                            setInput(e, ddown)
                                                          }
                                                        />
                                                      </div>
                                                      <Dropdown.Item
                                                        className="cmn_btn ms-0 mt-3"
                                                        onClick={(e) =>
                                                          applyinput(
                                                            e,
                                                            ddown,
                                                            filter[ddown].name
                                                          )
                                                        }
                                                      >
                                                        Apply
                                                      </Dropdown.Item>
                                                    </div>
                                                  )}
                                                {filter[ddown].type ==
                                                  "dropdown" &&
                                                  ddown == "pipeline" &&
                                                  filter[ddown].values?.map(
                                                    (pipvalue, pipindex) => {
                                                      return (
                                                        <Dropdown.Item
                                                          key={pipindex}
                                                          onClick={() => {
                                                            managefilter({
                                                              id: pipvalue?.pipelineId,
                                                              value:
                                                                pipvalue?.label,
                                                              name: "Market",
                                                            });
                                                          }}
                                                        >
                                                          {pipvalue?.label}
                                                        </Dropdown.Item>
                                                      );
                                                    }
                                                  )}
                                                {filter[ddown].type ==
                                                  "dropdown" &&
                                                  ddown == "statusId" &&
                                                  campaign_status_filter?.map(
                                                    (
                                                      statusvalue,
                                                      statusindex
                                                    ) => {
                                                      return (
                                                        <Dropdown.Item
                                                          key={statusindex}
                                                          onClick={() => {
                                                            managefilter({
                                                              id: statusvalue?.id,
                                                              value:
                                                                statusvalue?.description,
                                                              name: "Status",
                                                            });
                                                          }}
                                                        >
                                                          {
                                                            statusvalue?.description
                                                          }
                                                        </Dropdown.Item>
                                                      );
                                                    }
                                                  )}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        {/* <button className="cmn_btn" onClick={filter_submit}>
                          Go <AiOutlineArrowRight className="ms-2" />
                        </button> */}
                      </div>
                    </div>
                    <div className="">
                      <div className="campiagn_outer border-0 p-0">
                        <div className="advert_tabel campaign_showlist campign_index_table">
                          <Table
                            responsive
                            className={
                              filtered_campaign?.results?.length === 0
                                ? "no_data"
                                : null
                            }
                          >
                            <thead>
                              <tr>
                                <th
                                  onClick={() => {
                                    handlesort("id");
                                  }}
                                  className="text-start"
                                >
                                  Campaign ID <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                                  </svg>
                                </th>
                                <th
                                  onClick={() => {
                                    handlesort("name");
                                  }}
                                  className="text-start"
                                >
                                  Campaign Name <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                                  </svg>
                                </th>
                                <th
                                  onClick={() => {
                                    handlesort("product_id");
                                  }}
                                  className="text-start"
                                >
                                  Product <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                                  </svg>
                                </th>
                                <th
                                  onClick={() => {
                                    handlesort("budge");
                                  }}
                                  className="text-start"
                                >
                                  Budget in {dealCurrency} <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                                  </svg>
                                </th>
                                <th
                                  onClick={() => {
                                    handlesort("budget_usd");
                                  }}
                                  className="text-start"
                                >
                                  Budget in USD <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                                  </svg>
                                </th>
                                <th
                                  onClick={() => {
                                    handlesort("date_from");
                                  }}
                                  className="text-start"
                                >
                                  Start date <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                                  </svg>
                                </th>
                                <th
                                  onClick={() => {
                                    handlesort("date_to");
                                  }}
                                  className="text-start"
                                >
                                  End date <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                                  </svg>
                                </th>
                                <th
                                  onClick={() => {
                                    handlesort("status_id");
                                  }}
                                  className="text-start"
                                >
                                  Status <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
                                  </svg>
                                </th>
                                <th style={{ opacity: "0" }}>pdf</th>
                                {/* <th className='text-center'>Status Platform</th> */}
                              </tr>
                            </thead>
                            <tbody>

                              {filteredCampaignData?.map((curelem, index) => {
                                return (
                                  <tr
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <td
                                      className="text-start"
                                      onClick={() => {
                                        gotolineitems(
                                          curelem?.id,
                                          currency_code,
                                          remainingBudget,
                                          curelem?.statusNavigation
                                            ?.description
                                        );
                                      }}
                                    >
                                      {curelem?.id}
                                    </td>
                                    <td
                                      className="text-start"
                                      onClick={() => {
                                        gotolineitems(
                                          curelem?.id,
                                          currency_code,
                                          remainingBudget,
                                          curelem?.statusNavigation
                                            ?.description
                                        );
                                      }}
                                    >
                                      {curelem?.name}
                                    </td>
                                    <td
                                      className="text-start"
                                      onClick={() => {
                                        gotolineitems(
                                          curelem?.id,
                                          currency_code,
                                          remainingBudget,
                                          curelem?.statusNavigation
                                            ?.description
                                        );
                                      }}
                                    >
                                      {curelem?.productNavigation?.name}
                                    </td>
                                    <td
                                      className="text-start"
                                      onClick={() => {
                                        gotolineitems(
                                          curelem?.id,
                                          currency_code,
                                          remainingBudget,
                                          curelem?.statusNavigation
                                            ?.description
                                        );
                                      }}
                                    >
                                      {curelem?.budge?.toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 2 }
                                      )}
                                    </td>
                                    <td
                                      className="text-start"
                                      onClick={() => {
                                        gotolineitems(
                                          curelem?.id,
                                          currency_code,
                                          remainingBudget,
                                          curelem?.statusNavigation
                                            ?.description
                                        );
                                      }}
                                    >
                                      {curelem?.budget_usd?.toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 2 }
                                      )}
                                    </td>
                                    <td
                                      className="text-start"
                                      onClick={() => {
                                        gotolineitems(
                                          curelem?.id,
                                          currency_code,
                                          remainingBudget,
                                          curelem?.statusNavigation
                                            ?.description
                                        );
                                      }}
                                    >
                                      <Moment format="DD/MM/YYYY">
                                        {curelem?.date_from ? curelem?.date_from : moment()}
                                      </Moment>
                                    </td>
                                    <td
                                      className="text-start"
                                      onClick={() => {
                                        gotolineitems(
                                          curelem?.id,
                                          currency_code,
                                          remainingBudget,
                                          curelem?.statusNavigation
                                            ?.description
                                        );
                                      }}
                                    >
                                      <Moment format="DD/MM/YYYY">
                                        {curelem?.date_to ? curelem?.date_to : moment()}
                                      </Moment>
                                    </td>
                                    <td className="text-start">
                                      <Dropdown className="status_dropdown media_plan_dropdown status_design">
                                        <Dropdown.Toggle className={`filter_btn ${curelem?.statusNavigation?.description.toLowerCase()}`}>
                                          {curelem?.statusNavigation
                                            ?.description == 'DRAFT' && <img src="/assets/images/draft_FILL.svg" />}
                                          {curelem?.statusNavigation
                                            ?.description == 'ACTIVE' && <img src="/assets/images/check_circle.svg" />}
                                          {curelem?.statusNavigation
                                            ?.description == 'PAUSE' && <img src="/assets/images/pause_circle.svg" />}
                                          {curelem?.statusNavigation
                                            ?.description == 'INACTIVE' && <img src="/assets/images/cancel_FILL.svg" />}

                                          <span>  {
                                            upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(curelem?.statusNavigation
                                              ?.description))                                            
                                          }</span>
                                          <FaChevronRight className="ms-auto" />
                                        </Dropdown.Toggle>
                                        {permission?.Permissions[5] && (
                                            <Dropdown.Menu>
                                              {campaign_status_filter?.map(
                                                (status, index) => {
                                                  console.log(status, '----')
                                                  return (
                                                    <Dropdown.Item
                                                      className={status?.description.toLowerCase()}
                                                      key={`status_${index}`}
                                                      onClick={() => {
                                                        StatusPopup(
                                                          curelem?.id,
                                                          status?.id,
                                                          status?.description
                                                        );
                                                      }}
                                                    >
                                                      {status?.description == 'DRAFT' && <img src="/assets/images/draft_FILL.svg" />}
                                                      {status?.description == 'ACTIVE' && <img src="/assets/images/check_circle.svg" />}
                                                      {status?.description == 'PAUSE' && <img src="/assets/images/pause_circle.svg" />}
                                                      {status?.description == 'INACTIVE' && <img src="/assets/images/cancel_FILL.svg" />}
                                                      {upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(status?.description))}
                                                    </Dropdown.Item>
                                                  );
                                                }
                                              )}
                                            </Dropdown.Menu>
                                          )}
                                      </Dropdown>
                                    </td>
                                    <td className="text-right">

                                      <div className="icons_downloads" title="Edit Campaign">

                                        {permission?.Permissions[5] && <div className="pdf_icon" onClick={() => {
                                          setModalCampaignShow(true)
                                          setEditCampaignId(curelem?.id)
                                          setCampaignActualStatus(curelem?.statusNavigation?.description)
                                          setStartDateCampaign(curelem?.date_from)
                                          setEndDateCampaign(curelem?.date_to)
                                        }}>
                                          <img src="/assets/images/edit_fill.svg" />
                                        </div>}
                                        <div className="pdf_icon" title="View Line Items" onClick={() => {
                                          gotolineitems(
                                            curelem?.id,
                                            currency_code,
                                            remainingBudget,
                                            curelem?.statusNavigation
                                              ?.description
                                          );
                                        }}>
                                          <img src="/assets/images/description_file.svg" />
                                        </div>
                                      </div>
                                    </td>
                                    {/* <td className='text-center' onClick={() => { gotolineitems(curelem?.id, start_Date, end_Date, currency_code, remainingBudget) }}>{curelem?.plateformStatus === "ENTITY_STATUS_DRAFT" && "Draft" || curelem?.plateformStatus === "ENTITY_STATUS_PAUSED " && "Paused" || curelem?.plateformStatus === "ENTITY_STATUS_ARCHIVED " && "Archieved" || curelem?.plateformStatus === "ENTITY_STATUS_ACTIVE" && "Active" || curelem?.plateformStatus === null && "pending"}</td> */}
                                  </tr>
                                );
                              })}

                            </tbody>
                          </Table>
                          {filteredCampaignData?.length == 0 && <div className="no_data_cmn">
                            <img
                              className="nodata"
                              src="/assets/images/notfound.svg"
                            />
                          </div>
                          }
                          {filteredCampaignData?.length !== 0 && <div className="cmn__pagination">
                            <ul>
                              <span>Rows per page</span>
                              <li className="no__rows">                                
                                <select
                                  name=""
                                  id=""
                                  onChange={(e) => setPageLimit(e.target.value)}

                                >
                                  {/* <option value="5">5</option> */}
                                  <option value="10">10</option>
                                  <option value="15">15</option>
                                  <option value="20">20</option>
                                </select>
                              </li>
                              <li className="total_pages me-3">
                                <span className="active_pages">{current_Page}</span> of <span>{page_Count}</span>
                              </li>
                              <li>
                                <BsChevronBarLeft onClick={() => setPageNo(1)} />
                              </li>
                              <li

                              >
                                <BsChevronLeft onClick={() => setPageNo(pageNo - 1)} />
                              </li>
                              <li

                              >
                                <BsChevronRight onClick={() => setPageNo(pageNo + 1)} />
                              </li>
                              <li>
                                <BsChevronBarRight onClick={() => setPageNo(page_Count)} />
                              </li>
                            </ul>
                          </div>}
                        </div>

                        {/* <div className="cmn_pagination">
                          <ReactPaginate
                            nextLabel={<BiChevronsRight />}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={filtered_campaign?.page_count}
                            previousLabel={<BiChevronsLeft />}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                          />
                        </div> */}
                      </div>
                    </div>
                  </Tab>
                  {/* <Tab eventKey="profile" title="Optimize">
                    <div className="">
                      <div className="campiagn_outer border-0 p-0">
                        <div className="advert_tabel campaign_showlist optimize_table ">
                          <Table
                            responsive
                            className={
                              filtered_campaign?.results?.length === 0
                                ? "no_data"
                                : null
                            }
                          >
                            <thead>
                              <tr>
                                <th>Name campaign </th>
                                <th>ID</th>
                                <th>Name line item</th>
                                <th> Active creatives</th>
                                <th>Inventory Type</th>
                                <th>Line item status</th>
                                <th>Line Item Status in platform</th>
                                <th>Devices</th>
                                <th> Metrics updated to date</th>
                                <th>Country</th>
                                <th> Media plan currency</th>
                                <th>Offer type</th>
                                <th>Format</th>
                                <th>Goal</th>
                                <th>Daily Goal</th>
                                <th>Delivered to date</th>
                                <th>% delivered to date</th>
                                <th>% of completion</th>
                                <th> Margen setup</th>
                                <th>Margin achieved</th>
                                <th>Actual Cost</th>
                                <th>Max Cost</th>
                                <th> Start Date</th>
                                <th>End Date</th>
                                <th>Total Lenght</th>
                                <th>Flight</th>
                                <th>Remaining Days</th>
                                <th>Clicks</th>
                                <th>% CTR</th>
                                <th>% VCR</th>
                                <th>Media List</th>
                              </tr>
                            </thead>
                            <tbody>
                              {optimizeData?.map((data, index) => {
                                return (
                                  <>
                                    <tr key={index}>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                      >
                                        <BsChevronDown className="me-2 stroke_width" />
                                        <span>{data?.campaginName}</span>
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                      >
                                        {data?.id}
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                      >
                                        {data?.name}
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                      >
                                        {data?.creatives?.length}
                                      </td>
                                      <td>
                                        {data?.inventoryType?.description}
                                      </td>
                                      <td className="border">
                                        <div className="filter_menu ">
                                          <Dropdown className="advert">
                                            <Dropdown.Toggle id="dropdown-autoclose-true">
                                              <span className="active__option font_bold">
                                                {" "}
                                                {data?.stausName?.description}
                                              </span>{" "}
                                              <FaChevronRight />
                                            </Dropdown.Toggle>
                                            {data?.stausName?.id !== 28 && (
                                              <Dropdown.Menu className="child_filters">
                                                {line_item_status?.map(
                                                  (status, index) => {
                                                    return (
                                                      <Dropdown.Item
                                                        key={`status_${index}`}
                                                        onClick={() => {
                                                          StatusPopup1(
                                                            data?.id,
                                                            status?.id,
                                                            status?.description
                                                          );
                                                        }}
                                                      >
                                                        {status?.description}
                                                      </Dropdown.Item>
                                                    );
                                                  }
                                                )}
                                              </Dropdown.Menu>
                                            )}
                                          </Dropdown>
                                        </div>
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        {(data?.plateformStatus === null &&
                                          "Pending") ||
                                          (data?.plateformStatuss ===
                                            "ENTITY_STATUS_ACTIVE" &&
                                            "Active") ||
                                          (data?.plateformStatus ===
                                            "ENTITY_STATUS_ARCHIVED" &&
                                            "Archieved") ||
                                          (data?.plateformStatus ===
                                            "ENTITY_STATUS_PAUSED" &&
                                            "Paused") ||
                                          (data?.plateformStatus ===
                                            "ENTITY_STATUS_DRAFT" &&
                                            "Draft")}{" "}
                                      </td>
                                      <td className="border">
                                        <div className="filter_menu devices_menu">
                                          <Dropdown className="advert">
                                            <Dropdown.Toggle id="dropdown-autoclose-true">
                                              {data?.device?.map((device) => {
                                                let Deviceindex = devices?.find(
                                                  (item) =>
                                                    item.id === device?.deviceId
                                                );
                                                if (
                                                  Object.keys(Deviceindex)
                                                    ?.length > 0
                                                ) {
                                                  return (
                                                    <>
                                                      <span className="devices_names">
                                                        {Deviceindex?.name},
                                                      </span>
                                                    </>
                                                  );
                                                }
                                              })}
                                              <FaChevronRight />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="child_filters">
                                              {devices?.map((device) => {
                                                return (
                                                  <>
                                                    <Dropdown.Item>
                                                      <input
                                                        type="checkbox"
                                                        name=""
                                                        id=""
                                                        onClick={(e) => {
                                                          handleDevice(
                                                            device?.id,
                                                            e,
                                                            data?.id
                                                          );
                                                        }}
                                                      />{" "}
                                                      <span>
                                                        {device?.name}
                                                      </span>
                                                    </Dropdown.Item>
                                                  </>
                                                );
                                              })}
                                              <div className="footer__dropdown mb-2">
                                                <button>Clean</button>
                                                <button
                                                  onClick={() => {
                                                    handleUpdtaeDevices(
                                                      data?.id
                                                    );
                                                  }}
                                                >
                                                  Apply
                                                </button>
                                              </div>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        {data?.metrMetricsupdatedtodate?.date
                                          ? data?.metrMetricsupdatedtodate?.date
                                          : "Not Available"}
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        {data?.country?.map(
                                          (countries, index) => {
                                            return <span>{countries?.ab}</span>;
                                          }
                                        )}
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        {data?.currencyCode}
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        {data?.offertype?.descripcion}
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        {data?.format?.name}
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        {parseInt(
                                          data?.inversionGoal
                                        )?.toLocaleString()}{" "}
                                        <span className="info_badge">I</span>
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        {(
                                          data?.inversionGoal /
                                          moment(data?.dateTo).diff(
                                            moment(data?.dateFrom),
                                            "days"
                                          )
                                        ).toLocaleString()}
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        {data?.deliverytodate?.CpmOffer
                                          ? data?.deliverytodate?.CpmOffer
                                          : "Not Available"}
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        {data?.deliverytodate?.CpmOffer /
                                          ((data?.inversionGoal /
                                            moment(data?.dateTo).diff(
                                              moment(data?.dateFrom),
                                              "days"
                                            )) *
                                            moment(data?.dateFrom).diff(
                                              moment(
                                                data?.metrMetricsupdatedtodate
                                                  ?.date
                                              ),
                                              "days"
                                            ))}
                                        <img
                                          src="/assets/images/sentiment_very_satisfied.svg"
                                          className="ms-2"
                                          alt=""
                                        />
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        {data?.deliverytodate?.CpmOffer /
                                          data?.inversionGoal}
                                      </td>
                                      <td
                                        onClick={() => setActiveTab(!activeTab)}
                                        className="border"
                                      >
                                        <select
                                          name="inversion_netMargin"
                                          className="form-control"
                                          value={data?.inversionNetMargin}
                                          onChange={(e) => {
                                            handleUpdtaeMargin(data?.id, e);
                                          }}
                                        >
                                          <option value="">--Select--</option>
                                          <option value="40">40%</option>
                                          <option value="45">45%</option>
                                          <option value="50">50%</option>
                                          <option value="55">55%</option>
                                          <option value="60">60%</option>
                                          <option value="65">65%</option>
                                          <option value="70">70%</option>
                                          <option value="75">75%</option>
                                          <option value="80">80%</option>
                                          <option value="85">85%</option>
                                          <option value="90">90%</option>
                                        </select>
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        Margin Achieved
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        Actual Cost
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        {data?.maxCost}
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        <Moment format="DD/MM/YYYY">
                                          {data?.dateFrom}
                                        </Moment>
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        <Moment format="DD/MM/YYYY">
                                          {data?.dateTo}
                                        </Moment>
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        {moment(data?.dateTo).diff(
                                          moment(data?.dateFrom),
                                          "days"
                                        )}
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        <Moment format="DD/MM/YYYY">
                                          {moment() <= data?.dateTo
                                            ? moment(data?.dateTo).diff(
                                              moment(),
                                              "days"
                                            )
                                            : data?.dateTo}
                                        </Moment>
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        {moment(data?.dateTo).diff(
                                          moment(data?.dateFrom),
                                          "days"
                                        ) -
                                          (moment() <= data?.dateTo
                                            ? moment(data?.dateTo).diff(
                                              moment(),
                                              "days"
                                            )
                                            : data?.dateTo)}
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        25.265
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        {data?.ctr?.ctr
                                          ? data?.ctr?.ctr
                                          : "Not Available"}
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (data?.creatives?.length >= 2) {
                                            setIndexNo(index);
                                            setActiveTab(!activeTab);
                                          }
                                        }}
                                        className="border"
                                      >
                                        {data?.vcr?.vcr
                                          ? data?.vcr?.vcr
                                          : "Not Available"}
                                      </td>
                                      <td className="border text-primary">
                                        <a
                                          onClick={() =>
                                            navigation(
                                              `/campaignlist/${data?.id}`
                                            )
                                          }
                                        >
                                          View
                                        </a>
                                      </td>
                                    </tr>
                                    {indexNo === index &&
                                      activeTab &&
                                      data?.creatives?.map((creative) => {
                                        return (
                                          <>
                                            <tr>
                                              <td></td>
                                              <td className="border-bottom-1">
                                                {creative?.id}
                                              </td>
                                              <td className="border-bottom-1">
                                                {creative?.name}
                                              </td>
                                              <td></td>
                                              <td></td>
                                              <td className="border">
                                                <span>{creative?.enabled}</span>
                                              </td>
                                              <td className="border">
                                                {(creative?.approvalStatus ===
                                                  null &&
                                                  "Pending") ||
                                                  (creative?.approvalStatus ===
                                                    "APPROVAL_STATUS_UNSPECIFIED" &&
                                                    "Unspecified") ||
                                                  (creative?.approvalStatus ===
                                                    "APPROVAL_STATUS_PENDING_NOT_SERVABLE" &&
                                                    "Under Review") ||
                                                  (creative?.approvalStatus ===
                                                    "APPROVAL_STATUS_PENDING_SERVABLE" &&
                                                    "Under Review") ||
                                                  (creative?.approvalStatus ===
                                                    "APPROVAL_STATUS_APPROVED_SERVABLE" &&
                                                    "Approved") ||
                                                  (creative?.approvalStatus ===
                                                    "APPROVAL_STATUS_REJECTED_NOT_SERVABLE" &&
                                                    "Rejected")}
                                              </td>
                                              <td className="border"> </td>
                                              <td className="border"></td>
                                              <td className="border"></td>
                                              <td className="border"></td>
                                              <td className="border"></td>
                                              <td className="border"></td>
                                              <td className="border">
                                                <span className="info_badge">
                                                  I
                                                </span>
                                              </td>
                                              <td className="border"></td>
                                              <td className="border">
                                                {creative?.delivertodate
                                                  ?.CpmOffer
                                                  ? creative?.delivertodate
                                                    ?.CpmOffer
                                                  : "Not Available"}
                                              </td>
                                              <td className="border"></td>
                                              <td className="border"></td>
                                              <td className="border"></td>
                                              <td className="border"></td>
                                              <td className="border"></td>
                                              <td className="border"></td>
                                              <td className="border"></td>
                                              <td className="border"></td>
                                              <td className="border"></td>
                                              <td className="border"></td>
                                              <td className="border"></td>
                                              <td className="border">25.265</td>
                                              <td className="border">
                                                {creative?.ctr?.ctr
                                                  ? creative?.ctr?.ctr
                                                  : "Not Available"}
                                              </td>
                                              <td className="border">
                                                {creative?.vcr?.vcr
                                                  ? creative?.vcr?.vcr
                                                  : "Not Available"}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                  </>
                                );
                              })}

                            </tbody>
                          </Table>
                          <div className="cmn__pagination">
                            <ul>
                              <li className="no__rows">
                                <span>Rows per page</span>
                                <select
                                  name=""
                                  id=""
                                  onChange={(e) => setSize(e.target.value)}
                                >
                                  <option value="5">5</option>
                                  <option value="10">10</option>
                                  <option value="15">15</option>
                                  <option value="20">20</option>
                                </select>
                              </li>
                              <li onClick={() => setRowPage(pageCount)}>
                                <BsChevronBarLeft />
                              </li>
                              <li
                                onClick={() =>
                                  setRowPage((prevCount) => prevCount - 1)
                                }
                              >
                                <BsChevronLeft />
                              </li>
                              <li
                                onClick={() =>
                                  setRowPage((prevCount) => prevCount + 1)
                                }
                              >
                                <BsChevronRight />
                              </li>
                              <li onClick={() => setRowPage(1)}>
                                <BsChevronBarRight />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab> */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered

      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            New Campaign
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewCompaign
            setModalShow={setModalShow}
            dealid={dealid}
            deal_id={deal_id}
            deal_detail={deal_detail}
            clientId={deal_detail?.advertiserId}
            start_date={deal_detail?.dateFrom}
            end_date={deal_detail?.dateTo}
            dealCurrency={dealCurrency}
            searchFilter={searchFilter}
            totalBudget={deal_detail?.budgetDeal}
            sumBudget={deal_detail?.campaignTotalBudget}
            pendingBudget={
              deal_detail?.budgetDeal - deal_detail?.campaignTotalBudget
            }
            currencyCode={currencyCode}
            getData={getData}
            getCampaignInfo={getCampaignInfo} 
            getReloadData={getReloadData}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={statusmodalShow}
        onHide={() => {
          setStatusModalShow(false);
          resetstatus();
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="model_content">
            <div className='text-center mb-4'>
              <FiAlertCircle className='alert_icon' size={64} />
            </div>
            <h4 className="text-center border-bottom pb-5"> {statusmodel?.messageText}</h4>
            <div className="d-flex justify-content-end mt-5">
              <button
                className="me-4 cancel_cm_btn"
                onClick={() => setStatusModalShow(false)}
              >
                Cancel
              </button>
              <button
                className={statusmodel?.buttonClass}
                onClick={() => {
                  updateStatus();
                }}
              >
                {statusmodel?.buttonText}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={statusmodalShow1}
        onHide={() => {
          setStatusModalShow1(false);
          resetstatus();
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="model_content">
            <div className='text-center mb-4'>
              <FiAlertCircle className='alert_icon' size={64} />
            </div>
            <h4 className="text-center border-bottom pb-5"> {statusmodel?.messageText}</h4>
            <div className="model_btn">
              <button
                className="cmn_btn cancel_cmn"
                onClick={() => setStatusModalShow1(false)}
              >
                Cancel
              </button>
              <button
                className={statusmodel?.buttonClass}
                onClick={() => {
                  updateStatus1();
                }}
              >
                {statusmodel?.buttonText}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modal_open}
        onHide={() => {
          setModal_open(false);
          resetstatus();
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h6 className="text-start">
            Because there is no extra budget decrease the budget from campaign
            and return.
          </h6>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalcampaignShow}
        onHide={() => { setModalCampaignShow(false); getCampaignInfo() }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Campaign
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditCampaign
            setModalShow={setModalCampaignShow}
            dealid={dealid}
            deal_id={deal_id}
            clientId={deal_detail?.advertiserId}
            start_date={deal_detail?.dateFrom}
            total_Budget={deal_detail?.budgetDeal}
            sum_Budget={deal_detail?.campaignTotalBudget}
            end_date={deal_detail?.dateTo}
            campaign_start_date={startDateCampaign}
            campaign_end_date={endDateCampaign}
            dealCurrency={dealCurrency}
            searchFilter={searchFilter}
            // campaign_detail={campaign_detail}
            // onRefresh={searchFilter}
            // getcampain={getcampain}
            // budget_used={campaign_detail?.lineItemTotalBudget}
            currency_code={currency_code}
            // remainingBudget={remainingBudget}
            campaignStat={campaignActualStatus}
            editCampaignId={editCampaignId}
            getReloadData={getReloadData}
            getData={getData}
            getCampaignInfo={getCampaignInfo}
          />
      </Modal.Body>
      </Modal>
    </div>
  );
};

export default Mediaplan;
