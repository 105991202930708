import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import Loader from '../Loader';
import { toast } from "react-hot-toast";

const CampaignList = () => {
	const params = useParams();
	const navigation = useNavigate();
	const [list, setList] = useState(null);
	const [list_item, setList_item] = useState([]);
	const [loadershown, setloadershown] = useState(false);
	const [checkedExclude, setCheckedExclude] = useState([]);
	const [disableUpdateExclude, setDisableUpdateExclude] = useState(true);

	useEffect(() => {
		getdata();
	}, []);

	const handleChange = (e, id) => {
		if(e.target.checked) {
			setCheckedExclude([...checkedExclude, id])
		} else {
			setCheckedExclude(checkedExclude.filter(excludedId => excludedId !== id));
		}
		
	};

	const handleChangeFilterList = (e) => {
		const { value } = e.target;
		// setSearchTerm(value);
	};

	const getdata = async () => {
		setloadershown(true)
		const response = await fetch(
			`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/getlineitemid/${params.id}`,
			{
				method: "GET",
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			}
		);
		const result = await response.json();
		setList_item(result);
		let item = result?.data?.listOriginalLineItemDto?.result;
		setList(item);
		item.forEach(listItem => {
			if(listItem.exclude == true) {
				setCheckedExclude(prevState => [...prevState, listItem.appid]);
			}
		});
		setloadershown(false)
	};

	const enableExcludeList = () => {
		setDisableUpdateExclude(false);
	}

	const updateExcludeList = async (list) => {
		const listKey = list.map((str) => ({ 'appid': str }));
		setloadershown(true)
		var raw = JSON.stringify({
			"exclude": listKey,
		  });
		const response = await fetch(
			`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/excludecampaignitems/${params.id}`,
			{
				method: "POST",
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
					'Content-Type':'application/json',
				},
				body: raw,
			}
		);
		setDisableUpdateExclude(true);
		toast.success("Updated list!");
		setloadershown(false)
	};	

	return (
		<>
			<div className="content_outer">
				<Loader showLoader={loadershown} />
				<div className="cmn_header_top">
					{/* <button onClick={() => navigation(-1)}>
								<AiOutlineLeft />{" "}
							</button>
							<div className="bredcrum">
								<p>Line Item Details</p>
							</div> */}
					<h3>
						<img src="/assets/images/chevron_left.svg" onClick={() => navigation(-1)} />
						Line Item Details
					</h3>
					{/*<div className="flex-grow-1 text-center">
            			<div className="cmn_header__search position-relative">
            			  <AiOutlineSearch size={24} />
            			  <input
            			    type="text"
            			    placeholder="Search by Id or Advertiser"
							onChange={handleChangeFilterList}
            			  />
            			</div>
					</div>*/}
          		</div>
				<div className="content">
					<div className="App">

						<div className="mediaplan_details media_plan_info_list kite_space_cmn">
							<div className="advert_tabel">
								<ul>
									<li>
										<strong>Inventory Type:</strong>:
										<p>{list_item?.data?.inventoryNavigation?.description}</p>
									</li>
									<li>
										<strong>Offer type</strong>:
										<p>{list_item?.data?.offerTypeNavigation?.descripcion}</p>
									</li>
									<li>
										<strong>Name</strong>:<p>{list_item?.data?.name} </p>
									</li>
									<li>
										<strong>Start Date</strong>:
										<p>
											{" "}
											<Moment format="DD/MM/YYYY">
												{list_item?.data?.date_from}
											</Moment>
										</p>
									</li>
									<li>
										<strong>End Date</strong>:
										<p>
											<Moment format="DD/MM/YYYY">
												{list_item?.data?.date_to}
											</Moment>
										</p>
									</li>
									<li>
										<strong>Format</strong>:
										<p> {list_item?.data?.formatNavigation?.name}</p>
									</li>
									<li>
										<strong>Goal</strong>:
										<p> {list_item?.data?.inversion_goal?.toLocaleString(
											undefined,
											{ minimumFractionDigits: 0 })}</p>
									</li>
									<li>
										<strong>Status</strong>:
										<p>
											{" "}
											{list_item?.data?.lineItemStatusNavigation?.description}
										</p>
									</li>
									<li>
										<strong>Country</strong>:
										<p>
											{" "}
											{list_item?.data?.countryList.map((list,index) => {
												return(
													<span>{list.country?.ab?.toUpperCase()}{index != list_item.data?.countryList.length -1 ? ',' : ''}&nbsp;</span>
												)
											})}
										</p>
									</li>
									<li>
										<strong>Ages</strong>:
										<p>
											{" "}
											{list_item?.data?.audienceList?.map((list,index) => {
												return(
													<span>{list?.audience?.age}{index != list_item.data?.audienceList.length -1 ? ',' : ''}&nbsp;</span>
												)
											})}
										</p>
									</li>
									<li>
										<strong>Gender</strong>:
										<p>
											{" "}
											{list_item?.data?.targetgender_name}
										</p>
									</li>
									{/* <li className="edit_campaign_details">
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                                        </svg>
                                    </li> */}
								</ul>
							</div>
						</div>
						<div className="new_compaign">
							<div className="campaign_up_list">
								<h2 className="cmn_title "> Line Items List</h2>
								<button className="cmn_search_btn"
                          			onClick={() => disableUpdateExclude ? enableExcludeList() : updateExcludeList(checkedExclude)}
                        		>
									{ disableUpdateExclude ? 'Edit List' : 'Save List' }
                        		</button>
							</div>
							<div className="">							
								<div className="campiagn_outer border-0 p-0">
									<div className="advert_tabel">
										<div className="table-responsive">
											<table
												className={
													!list?.result
														? "table table-striped mt-0 no_data"
														: "table table-striped mt-0 "
												}
											>
												<thead>
													<tr>
														<th className="text-center">Title</th>
														<th className="text-center">Id</th>
														<th className="text-center">Exclude</th>
													</tr>
												</thead>
												<tbody>
													{!list ? (
														<div>
															{" "}
															<img
																className="nodata"
																src="/assets/images/notfound.svg"
															/>{" "}
														</div>
													) : (
														list?.map((data,i) => {
															return (
																<>
																	<tr key={i}>
																		<td className="text-center">
																			{data?.title}
																		</td>
																		<td className="text-center">
																			{data?.appid}
																		</td>
																		<td className="text-center">
																		<input
																			defaultChecked={data.exclude}
                														  	// checked={data?.exclude}
																			disabled={disableUpdateExclude ? true : false}
                															onChange={(e) => handleChange(e, data?.appid) 
																				/*(
																				if(e.target.checked) {
																					setCheckedExclude([...checkedExclude, data?.appid])
																				}
																				)*/
																			}
                														  type="checkbox"
                														  className="mb-0"
                														/>
																		</td>
																	</tr>
																</>
															);
														})
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CampaignList;
