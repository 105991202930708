import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Multiselect from "multiselect-react-dropdown"
import { useSelector, useDispatch } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../Loader";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import Moment from "react-moment";
import moment from "moment";
import { FiAlertCircle } from 'react-icons/fi';
import {
    LineItemsSelector,
    getInventories,
    getOfferTypes,
    getLineitemFormats,
    getCountries,
    getGender,
    getAgeRange,
    getDevices,
    getPassionPoint,
    saveLineItem,
    clearsaveLineItem,
    updateLineItem,
    clearupdateLineItem,
} from "../../app/features/LineItemManager/LineItemManagerSlice";
const EditLineItems = (props) => {
    const dispatch = useDispatch();
    const [lineitem_inventory_name, setLineitem_inventory_name] = useState("")
    const [lineitem_inventory_type_id, setLineitem_inventory_type_id] = useState(null)
    const [lineitem_format, setLineitem_format] = useState(null)
    const [lineitem_offer_type, setLineitem_offer_type] = useState(null)
    const [lineitem_current_start_date, setLineitem_current_start_date] = useState("")
    const [lineitem_current_end_date, setLineitem_current_end_date] = useState("")
    const [lineitem_start_date, setLineitem_start_date] = useState("")
    const [lineitem_end_date, setLineitem_end_date] = useState("")
    const [lineitem_min_date, setLineitem_min_date] = useState("")
    const [lineitem_max_date, setLineitem_max_date] = useState("")
    const [lineitem_budget, setLineitem_budget] = useState(null)
    const [lineitem_sell_rate, setLineitem_sell_rate] = useState(null)
    const [lineitem_margin, setLineitem_margin] = useState(null)
    const [lineitem_net_rate, setLineitem_net_rate] = useState(null)
    const [lineitem_budget_usd, setLineitem_budget_usd] = useState(null)
    const [lineitem_sell_rate_usd, setLineitem_sell_rate_usd] = useState(null)
    const [lineitem_net_rate_usd, setLineitem_net_rate_usd] = useState(null)
    const [lineitem_goal, setLineitem_goal] = useState(null)
    const [lineitem_selected_country, setLineitem_selected_country] = useState([])
    const [lineitem_selected_country_id, setLineitem_selected_country_id] = useState([])
    const [lineitem_selected_devices, setLineite_selected_devices] = useState([])
    const [lineitem_selected_devices_id, setLineitem_selected_devices_id] = useState([])
    const [lineitem_gender, setLineitem_gender] = useState(null)
    const [lineitem_selected_age, setLineitem_selected_age] = useState([])
    const [lineitem_selected_age_id, setLineitem_selected_age_id] = useState([])
    const [lineitem_selected_passion_point, setLineitem_selected_passion_point] = useState([])
    const [lineitem_selected_passion_point_id, setLineitem_selected_passion_point_id] = useState([])
    const [lineitem_is_bonified, setLineitem_is_bonified] = useState(false)
    const [lineitem_is_reach, setLineitem_is_reach] = useState(false)
    const [lineitem_detail, setLineitem_detail] = useState([])
    const [loadershown, setloadershown] = useState(false);
    const [age_group, setAge_group] = useState([])
    const [confirm_delete, setConfirm_delete] = useState(false)
    const [deleted_lineitem_id, setDeleted_lineitem_id] = useState(null)
    const [modaleditShow, setModalEditShow] = useState(false)
    const [valueInventory, setValueInventory] = useState(false)
    const [check_update, setCheck_update] = useState(false)
    const [lineitem_pacing,setLineitem_pacing] = useState([])
    const [lineitem_pacing_rate,setLineitem_pacing_rate] = useState([])
    const [selected_pacing,setSelected_pacing] = useState(null)
    const [selected_pacing_rate,setSelected_pacing_rate] = useState(null)

    const permission = JSON.parse(localStorage.getItem('userdata'));
    //    this below function is used to get all the inventory types and all other countries and devices and passion points nitin 12/09/2023
    const {
        inventories,
        line_item_formats,
        offer_types,
        countries,
        genders,
        age_range,
        devices,
        passion_points,
        error,
        isSuccesssaveLineItem,
        isErrorsaveLineItem,
        inserted_lineitem_id,
        isSuccessupdateLineItem,
        isErrorupdateLineItem,
    } = useSelector(LineItemsSelector);
    console.log(props?.editStatus, "this is the status of lineitem")

    useEffect(() => {
        if (inventories?.length == 0) {
            dispatch(getInventories({ token: localStorage.getItem("token") }));
        }
        if (line_item_formats?.length == 0) {
            dispatch(getLineitemFormats({ token: localStorage.getItem("token") }));
        }
        if (offer_types?.length == 0) {
            dispatch(getOfferTypes({ token: localStorage.getItem("token") }));
        }

        if (countries?.length == 0) {
            dispatch(getCountries({ token: localStorage.getItem("token") }));
        }
        if (genders?.length == 0) {
            dispatch(getGender({ token: localStorage.getItem("token") }));
        }
        dispatch(getDevices({ token: localStorage.getItem("token") }));
        dispatch(getPassionPoint({ token: localStorage.getItem("token") }));
        dispatch(getAgeRange({ token: localStorage.getItem("token") }))

        setLineitem_min_date(new Date(props?.startDate) < new Date() ? new Date() : new Date(props?.startDate));
        setLineitem_max_date(new Date(props?.endDate));
    }, [])

    // this below function is used to get the details of net rate and goal etc. nitin 12/09/2023

    const getFormaulaData = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + localStorage.getItem("token")
        );

        var raw = JSON.stringify({
            deal_id: props?.dealid,
            inversion_sell_rate: lineitem_sell_rate ? lineitem_sell_rate : "",
            inversion_budget: lineitem_budget ? lineitem_budget : "",
            inversion_offer_type_id: lineitem_offer_type ? lineitem_offer_type : "",
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const response = await fetch(
            `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/get_Lineitem_formulas`,
            requestOptions
        );
        const result = await response.json();
        // setFormulaData(result);
        setLineitem_budget_usd(result?.data?.inversion_budget_usd);
        setLineitem_net_rate(result?.data?.inversion_netrate);
        setLineitem_net_rate_usd(result?.data?.inversion_netRate_usd);
        setLineitem_sell_rate_usd(result?.data?.inversion_sellRate_usd);
        setLineitem_goal(result?.data?.inversion_goal);
    };

    //this below funcion is used to set the multiple countries this is trigger from the multiselect country field nitin 12/09/2023
    const handle_multiselect_countries = (e) => {
        var allSelectedCountries = e.map((val) => val.id).filter(Boolean);
        setLineitem_selected_country_id(allSelectedCountries);
    }

    //this below function is used to set the multiple ages this is trigger from the multiselect age range field nitin 12/09/2023
    const handle_multiselect_age_range = (e) => {
        var allSelectedAges = e.map((val) => val.id).filter(Boolean);
        setLineitem_selected_age_id(allSelectedAges);
    }

    //this below function is used to set the multiple devices this is trigger from the devices field nitin 12/09/2023
    const handle_multiselect_devices = (e) => {
        var allSelectedDevices = e.map((val) => val.id).filter(Boolean);
        setLineitem_selected_devices_id(allSelectedDevices);
    }

    //this function is used to set the multiple passion points this is trigger from the passion points fields nitin 12/09/2023
    const handle_multiselect_passion_points = (e) => {
        var allSelectedPassionPoints = e.map((val) => val.id).filter(Boolean);
        setLineitem_selected_passion_point_id(allSelectedPassionPoints);
    }

    // this below function is used to select the dates nitin 12/09/2023
    const handleCallback = (start, end) => {
        setLineitem_start_date(start.format("YYYY-MM-DD"))
        setLineitem_end_date(end.format("YYYY-MM-DD"))
    }

    useEffect(() => {
        setloadershown(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/getlineitemid/${props?.lineItemId}`, requestOptions)
            .then(response => response.json())
            .then(result => { setLineitem_detail(result?.data); setloadershown(false) })
            .catch(error => console.log('error', error));
    }, [])

    //this below function is used to set all the values in fields list below nitin 12/09/2023
    useEffect(() => {
        if (lineitem_detail?.length != 0) {
            setLineitem_inventory_name(lineitem_detail?.name)
            setLineitem_inventory_type_id(lineitem_detail?.inventoryNavigation?.id)
            setLineitem_format(lineitem_detail?.formatNavigation?.id)
            setLineitem_start_date(new Date(lineitem_detail?.date_from))
            setLineitem_end_date(new Date(lineitem_detail?.date_to))
            setLineitem_current_start_date(new Date(lineitem_detail?.date_from))
            setLineitem_current_end_date(new Date(lineitem_detail?.date_to))
            setLineitem_budget(lineitem_detail?.inversion_budget)
            setLineitem_offer_type(lineitem_detail?.offerTypeNavigation?.id)
            // setLineitem_bid(lineitem_detail?.bid_kite)
            setLineitem_budget_usd(lineitem_detail?.inversion_budget_usd)
            // setLineitem_bid_usd(lineitem_detail?.bid_kite_usd)
            setLineitem_gender(lineitem_detail?.targetgender_id)
            setLineitem_sell_rate(lineitem_detail?.inversion_sell_rate)
            setLineitem_margin(lineitem_detail?.inversion_netMargin)
            setLineitem_net_rate(lineitem_detail?.inversion_netRate)
            setLineitem_sell_rate_usd(lineitem_detail?.inversion_sellRate_usd)
            setLineitem_net_rate_usd(lineitem_detail?.inversion_netRate_usd)
            setLineitem_goal(lineitem_detail?.inversion_goal)
            setLineitem_is_bonified(lineitem_detail?.is_bonificated)
            setSelected_pacing(lineitem_detail?.pacing_id)
            setSelected_pacing_rate(lineitem_detail?.pacing_rate)
            setLineitem_is_reach(lineitem_detail?.is_reach ? true : false)
            // if (lineitem_details?.countryList) {
            // 	let all_countries = []
            // 	lineitem_details?.countryList?.map((data) => {
            // 		all_countries.push(data?.country?.labelKey)
            // 	})  
            // 	setLineitem_countries(all_countries)
            // }
            if (lineitem_detail?.countryList) {
                let all_countries_id = []
                lineitem_detail?.countryList?.map((data) => {
                    all_countries_id.push({
                        id: data?.country?.id,
                        name: data?.country?.labelKey
                    })
                })
                setLineitem_selected_country(all_countries_id)
            }
            if (lineitem_detail?.countryList) {
                let all_countries_id = []
                lineitem_detail?.countryList?.map((data) => {
                    all_countries_id.push(
                        data?.country?.id
                    )
                })
                setLineitem_selected_country_id(all_countries_id)
            }
            if (lineitem_detail?.audienceList) {
                let all_age = []
                lineitem_detail?.audienceList?.map((data) => {
                    all_age.push({
                        id: data?.audience?.id,
                        name: data?.audience?.age
                    })
                })
                setLineitem_selected_age(all_age)
            }
            if (lineitem_detail?.audienceList) {
                let all_age_id = []
                lineitem_detail?.audienceList?.map((data) => {
                    all_age_id.push(
                        data?.audience?.id
                    )
                })
                setLineitem_selected_age_id(all_age_id)
            }
            if (lineitem_detail?.deviceList) {
                let devicesval = [];
                lineitem_detail?.deviceList.map((value) => {
                    let Deviceindex = devices.findIndex(
                        (item) => item.id === value?.deviceId
                    );
                    if (Deviceindex != -1) {
                        devicesval.push(devices[Deviceindex]);
                    }
                });
                setLineite_selected_devices(devicesval);
            }
            if (lineitem_detail?.deviceList) {
                let devicesval = [];
                lineitem_detail?.deviceList.map((value) => {
                    devicesval.push(value?.deviceId)
                });
                setLineitem_selected_devices_id(devicesval);
            }
            if (lineitem_detail?.passionPointList) {
                let passionpnt = [];
                lineitem_detail?.passionPointList.map((value) => {
                    let passionPointIndex = passion_points.findIndex(
                        (item) => item.id === value?.passionpointId
                    );
                    if (passionPointIndex != -1) {
                        passionpnt.push(passion_points[passionPointIndex]);
                    }
                });
                setLineitem_selected_passion_point(passionpnt);
            }
            if (lineitem_detail?.passionPointList) {
                let passionpnt = [];
                lineitem_detail?.passionPointList.map((value) => {
                    passionpnt.push(value?.passionpointId)
                });
                setLineitem_selected_passion_point_id(passionpnt);
            }
        }
    }, [lineitem_detail])

    const result = props?.total_Budget - props?.sum_Budget;

    useEffect(() => {
        if (lineitem_gender != lineitem_detail?.targetgender_id) {
            setCheck_update(true);
        }

        if (Array.isArray(lineitem_selected_age_id) && Array.isArray(lineitem_detail?.audienceList)) {
            // Make a copy of the array and sort it
            // let arr1 = [...lineitem_selected_age_id].sort();
            // let arr2 = [...lineitem_detail?.audienceList].sort();
            if (lineitem_selected_age_id?.length != lineitem_detail?.audienceList?.length) {
                setCheck_update(true);
            }
        }

        if (Array.isArray(lineitem_selected_passion_point_id) && Array.isArray(lineitem_detail?.passionPointList)) {
            // Make a copy of the array and sort it
            // let arr1 = [...lineitem_selected_passion_point_id].sort();
            // let arr2 = [...lineitem_detail?.passionPointList].sort();
            if (lineitem_selected_passion_point_id?.length !== lineitem_detail?.passionPointList?.length) {
                setCheck_update(true);
            }
        }
    }, [lineitem_gender, lineitem_selected_age_id, lineitem_selected_passion_point_id]);

    // Function to compare arrays
    // function arraysAreEqual(arr1, arr2) {
    //     if (arr1?.length != arr2?.length) {
    //         return true;
    //     }

    //     // for (let i = 0; i < arr1.length; i++) {
    //     //     if (arr1[i] != arr2[i]) {
    //     //         return true;
    //     //         break;
    //     //     }
    //     // }

    //     return false;
    // }


    // this below function is used to update the lineitems nitin 12/09/2023
    const handle_update_lineitem = async () => {


        if (lineitem_inventory_name === "") {
            toast.error("Lineitem name can't be empty!!")
        } else if (lineitem_inventory_type_id === null) {
            toast.error("Please select inventory type !!")
        } else if (lineitem_format === null) {
            toast.error("Please select format !!")
        } else if (lineitem_start_date === "") {
            toast.error("please select start date !!")
        } else if (lineitem_end_date === "") {
            toast.error("Please select end date !!")
        } else if (lineitem_budget === null) {
            toast.error("Budget field can't be empty")
        } else if (!lineitem_is_bonified && lineitem_budget > (result + lineitem_detail?.inversion_budget)) {
            toast.error(`Budget field can't be greater than ${result + lineitem_detail?.inversion_budget}`)
        }
        else if (lineitem_sell_rate === null) {
            toast.error("Sell rate can't be empty !!")
        } else if (lineitem_offer_type === null) {
            toast.error("please select offer type")
        } else if (setLineitem_selected_country_id?.length === 0) {
            toast.error("Please select atleast one country !!")
        } else if (lineitem_gender === null) {
            toast.error("please select gender !!")
        } else if (lineitem_selected_devices_id?.length === 0) {
            toast.error("Please select atleast one device !!")
        } else if (setLineitem_selected_age_id?.length === 0) {
            toast.error("please select atleast one age group !!")
        }
        else {
            setloadershown(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append(
                "Authorization",
                "Bearer " + localStorage.getItem("token")
            );

            var raw = JSON.stringify({
                name: lineitem_inventory_name,
                deal_id: props?.dealid,
                campaign_id: props?.campaignId,
                inventory_type_id: lineitem_inventory_type_id,
                format_id: lineitem_format,
                date_from: lineitem_start_date,
                date_to: lineitem_end_date,
                inversion_budget: lineitem_budget,
                inversion_sell_rate: lineitem_sell_rate,
                inversion_offer_type_id: lineitem_offer_type,
                inversion_goal: lineitem_goal,
                target_country_id: lineitem_selected_country_id,
                inversion_netMargin: permission?.Permissions[22] ? 0.50 : lineitem_margin,
                target_gender_id: lineitem_gender,
                // target_frequency: values?.target_frequency,
                //   black_list: values?.black_list,
                inversion_budget_usd: lineitem_budget_usd,
                inversion_netRate_usd: lineitem_net_rate_usd,
                inversion_sellRate_usd: lineitem_sell_rate_usd,
                inversion_netRate: lineitem_net_rate,
                lineitem_devices: lineitem_selected_devices_id,
                lineitem_passion_points: lineitem_selected_passion_point_id,
                audiences: lineitem_selected_age_id,
                is_bonificated: lineitem_is_bonified,
                Check_update: check_update,
                pacing_id: selected_pacing ? selected_pacing : null,
                pacing_rate: selected_pacing_rate ? selected_pacing_rate : null,
                is_reach: lineitem_is_reach ? 1 : 0
            });

            var requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            try {
                const response = await fetch(
                    `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/update-lineitem/${props?.lineItemId}`,
                    requestOptions
                );
                const result = await response.json();
                if (!result.success) {
                    const errorMessage = result.message || "An error occurred";
                    throw new Error(errorMessage);
                }
                return toast.success("Lineitem updated successfully");

            } catch (error) {
                toast.error(error.message);
            } finally {
                props.setEditLineItemModal(false);
                props.onRefresh();
                props.getcampain();
                props?.getData()
                setCheck_update(false)
            }

            //     const response = await fetch(
            //         `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/update-lineitem/${props?.lineItemId}`,
            //         requestOptions
            //     );
            //     const result = await response.json();
            //     if (!result.ok) {
            //         const errorMessage = result.message || "An error occurred";
            //         throw new Error(errorMessage);
            //       }
            //       toast.success("lineitem updated successfully");
            //       props.setEditLineItemModal(false);
            //       props.onRefresh();
            //       props.getcampain();
            //       props?.getData()
            //       setCheck_update(false)
            //     if (result) {
            //         setloadershown(false);
            //         toast.success("lineitem updated successfully");
            //         props.setEditLineItemModal(false);
            //         props.onRefresh();
            //         props.getcampain();
            //         props?.getData()
            //         setCheck_update(false)
            //         setloadershown(false);
            //     }
            //     else {
            //         toast.error("Some Internal Error Occurred !!");
            //         setloadershown(false);
            //     }
            //  }
        }
    }

    // this function is triggered when we edit the only end date means when the lineitem is active nitin 12/09/2023
    const handleCallbackedit = (start, end) => {
        setLineitem_end_date(end.format("YYYY-MM-DD"))
    }

    //this below function is used to remove id 1 from the age group nitin 12/09/2023
    useEffect(() => {
        if (age_range) {
            let ages = []
            age_range?.map((data) => {
                if (data?.id !== 1) {
                    ages.push({
                        id: data?.id,
                        age: data?.age
                    })
                }
                setAge_group(ages)

            })
        }
    }, [age_range])

    //this function is used to delete the lineitem it is a pop up that confirm do you want to delete or not nitin 13/09/2023
    const handlePopUpDelete = (id) => {
        //creating a modal to confirmation
        setConfirm_delete(true)
        //when this state becomes true it open a confirmation modal
        //setting up the id that is going to delete
        setDeleted_lineitem_id(id)
    }

    // this below function is used to delete the lineitem nitin 13/09/223
    const handleDelete = async (id) => {
        if (props?.lineItemStat === "ACTIVE") {
            setModalEditShow(true)
        } else {
            setConfirm_delete(false)
            setloadershown(true)
            const response = await fetch(
                `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/delete-lineItem/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            const result = await response.json();
            if (result) {
                props.setEditLineItemModal(false);
                props.getData();
                props.getcampain();
                props.setModalShow(false);
                props.searchFilter();
                setloadershown(false)
                toast.success("Deleted successfully");
            } else {
                toast.error("something went wrong");
            }
        }
    };

    // this below function is used to set the country fiels for single country and multiple nitin 13/09/2023
    useEffect(() => {
        if (lineitem_inventory_type_id == 4) {
            setValueInventory(true)
        } else {
            setValueInventory(false)
        }
    }, [lineitem_inventory_type_id])

    //    this below function is uded to remove selected countries from thw main array nitin 18/09/2023
    const handle_multiremove_country = (e) => {
        var allSelectedCountries = e.map((val) => val.id);
        setLineitem_selected_country_id(allSelectedCountries);
    }

    //    this below function is uded to remove selected devices from thw main array nitin 18/09/2023
    const handle_multiremove_device = (e) => {
        var allSelectedDevices = e.map((val) => val.id);
        setLineitem_selected_devices_id(allSelectedDevices);
    }

    //    this below function is uded to remove selected ages from thw main array nitin 18/09/2023
    const handle_multiremove_age = (e) => {
        var allSelectedAges = e.map((val) => val.id);
        setLineitem_selected_age_id(allSelectedAges);
    }

    //    this below function is uded to remove selected passion points from thw main array nitin 18/09/2023
    const handle_multiremove_passion_points = (e) => {
        var allSelectedPassionPoints = e.map((val) => val.id);
        setLineitem_selected_passion_point_id(allSelectedPassionPoints);
    }

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+localStorage.getItem('token'));
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/getpacing`, requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result,"this is the pacing");setLineitem_pacing(result?.data)})
          .catch(error => toast.error(error));
      },[])

      useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+localStorage.getItem('token'));
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/getpacingrate`, requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result);setLineitem_pacing_rate(result?.data)})
          .catch(error => toast.error(error));
      },[])

    return (
        <div className='content pt-0'>
            <Loader showLoader={loadershown} />
            <label className="lable_title">Details</label>
            <div className="mb-3 row">
                <div className="col-lg-3">
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position " for="lignitemname">Line item Name*</label>
                        <input type="text" className="form-control"
                            name="name" placeholder="Item name"
                            value={lineitem_inventory_name}
                            onChange={(e) => setLineitem_inventory_name(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group select_country_container cmn_modal_field mb-3 bg-secondary">
                        <label className="lbl_position text-white" for="inventorytype">Inventory Type</label>
                        <select name="inventory_type_id" className="form-select bg-secondary text-white"
                            onChange={(e) => setLineitem_inventory_type_id(e.target.value)}
                            value={lineitem_inventory_type_id}
                            disabled={true}
                        >
                            <option value="">--Select--</option>
                            {
                                inventories?.map((all_inventory, index) => {
                                    if (all_inventory?.id === 2 || all_inventory?.id === 6) { return null }

                                    return (
                                        <option value={all_inventory?.id} key={index}>{all_inventory?.description}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group select_country_container cmn_modal_field mb-3 bg-secondary">
                        <label className="lbl_position text-white" for="format">Format</label>
                        <select name="format_id" className="form-select bg-secondary text-white"
                            value={lineitem_format}
                            onChange={(e) => setLineitem_format(e.target.value)}
                            disabled={true}
                        >
                            <option value="">--Select--</option>
                            {line_item_formats
                                .filter((format) => {
                                    // Filter formats based on the selected inventory type (lineitem_inventory)
                                    return (
                                        lineitem_inventory_type_id === '' || // Show all formats when nothing is selected
                                        (lineitem_inventory_type_id == '1' && format.id <= 9) || // Show certain formats for inventory type 1
                                        (lineitem_inventory_type_id == '2' && format.id === 11) || // Show certain formats for inventory type 2
                                        (lineitem_inventory_type_id == '3' && format.id === 11) || // Show certain formats for inventory type 3
                                        (lineitem_inventory_type_id == '4' && (format.id === 12 || format.id === 13 || format.id === 18)) || // Show certain formats for inventory type 4
                                        (lineitem_inventory_type_id == '5' && (format.id === 14 || format.id === 15 || format.id === 16 || format.id === 17)) // Show certain formats for inventory type 5
                                        // (lineitem_inventory_type_id === 6 &&  '') // Show certain formats for inventory type 6
                                    );
                                })
                                .map((selected_format) => {
                                    return (
                                        <option value={selected_format.id} key={selected_format.id}>
                                            {selected_format.name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position " for="ofertype">Offer Type</label>
                        <select name="inversion_offer_type_id" className="form-control"
                            value={lineitem_offer_type}
                            onChange={(e) => setLineitem_offer_type(e.target.value)}
                        >
                            <option value="">--Select--</option>
                            {lineitem_format == 12
                                ? offer_types?.map((offr, offr_index) => {
                                    if (offr.id == 5) {
                                        return (
                                            <option value={offr?.id} key={offr_index}>
                                                {offr?.descripcion}
                                            </option>
                                        );
                                    }
                                })
                                : null}
                            {lineitem_format == 13
                                ? offer_types?.map((offr, offr_index) => {
                                    if (offr.id == 6 || offr.id == 5) {
                                        return (
                                            <option value={offr?.id} key={offr_index}>
                                                {offr?.descripcion}
                                            </option>
                                        );
                                    }
                                })
                                : null}
                            {lineitem_format == 3 ||
                                lineitem_format == 4 ||
                                lineitem_format == 5 ||
                                lineitem_format == 6 ||
                                lineitem_format == 7 ||
                                lineitem_format == 8 ||
                                lineitem_format == 9
                                ? offer_types?.map((offr, offr_index) => {
                                    if (offr.id == 5) {
                                        return (
                                            <option value={offr?.id} key={offr_index}>
                                                {offr?.descripcion}
                                            </option>
                                        );
                                    }
                                })
                                : null}
                            {lineitem_format == 2 ||
                                lineitem_format == 1 ||
                                lineitem_format == 10 ||
                                lineitem_format == 11
                                ? offer_types?.map((offr, offr_index) => {
                                    if (offr.id == 5 || offr.id == 7) {
                                        return (
                                            <option value={offr?.id} key={offr_index}>
                                                {offr?.descripcion}
                                            </option>
                                        );
                                    }
                                })
                                : null}
                            {lineitem_format == 14 ||
                                lineitem_format == 15
                                ? offer_types?.map((offr, offr_index) => {
                                    if (offr.id == 5) {
                                        return (
                                            <option value={offr?.id} key={offr_index}>
                                                {offr?.descripcion}
                                            </option>
                                        );
                                    }
                                })
                                : null}
                            {lineitem_format == 16
                                ? offer_types?.map((offr, offr_index) => {
                                    if (offr.id == 8) {
                                        return (
                                            <option value={offr?.id} key={offr_index}>
                                                {offr?.descripcion}
                                            </option>
                                        );
                                    }
                                })
                                : null}
                            {lineitem_format == 18
                                ? offer_types?.map((offr, offr_index) => {
                                    if (offr.id == 5) {
                                        return (
                                            <option value={offr?.id} key={offr_index}>
                                                {offr?.descripcion}
                                            </option>
                                        );
                                    }
                                })
                                : null}
                            {lineitem_format == 17
                                ? offer_types?.map((offr, offr_index) => {
                                    if (offr.id == 9) {
                                        return (
                                            <option value={offr?.id} key={offr_index}>
                                                {offr?.descripcion}
                                            </option>
                                        );
                                    }
                                })
                                : null}
                        </select>
                    </div>
                </div>
            </div>
            {/*  */}
            <label className="lable_title">Pricing and budget
            </label>
            <div className="mb-3 row">
                {props?.editStatus !== "ACTIVE" &&
                    <>
                        <div className="col-lg-3">
                            <div className="form-group mb-3 select_country_container cmn_modal_field">
                                <label className="lbl_position" for="startdate">Start Date</label>
                                <DatePicker
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={lineitem_min_date}
                                    maxDate={lineitem_max_date}
                                    value={lineitem_start_date}
                                    onChange={date => setLineitem_start_date(date)}
                                    selected={lineitem_start_date}
                                />
                                {/*<DateRangePicker
                                    initialSettings={{
                                        startDate:
                                            new Date(props?.startDate) < new Date()
                                                ? new Date()
                                                : new Date(props?.startDate),
                                        endDate: new Date(props?.endDate),
                                        minDate:
                                            new Date(props?.startDate) < new Date()
                                                ? new Date()
                                                : new Date(props?.startDate),
                                        maxDate: new Date(props?.endDate),
                                        locale: {
                                            format: "DD/MM/YYYY", // Specify the desired date format here
                                        },
                                    }}
                                    onCallback={handleCallback}
                                >
                                    <input type="text" className="form-control" />
                                </DateRangePicker>*/}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group mb-3 select_country_container cmn_modal_field">
                                <label className="lbl_position" for="startdate">End Date</label>
                                <DatePicker
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={lineitem_min_date}
                                    maxDate={lineitem_max_date}
                                    value={lineitem_end_date}
                                    onChange={date =>
                                        date > lineitem_start_date ?
                                            (setLineitem_end_date(date)) :
                                            (toast.error("End date must be after start date"))
                                    }
                                    selected={lineitem_end_date}
                                />
                            </div>
                        </div>
                        
                    </>
                }
                {props?.editStatus === "ACTIVE" && (moment(lineitem_detail?.date_from) < moment()) && (
                    <div className="col-lg-3">
                        <div className="form-group mb-3 select_country_container cmn_modal_field"><label className="lbl_position" for="startdate">End Date</label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                minDate={lineitem_min_date}
                                maxDate={lineitem_max_date}
                                value={lineitem_end_date}
                                onChange={date => setLineitem_end_date(date)}
                                selected={lineitem_end_date}
                            />
                            {/*<DateRangePicker
                                initialSettings={{

                                    endDate: new Date(props?.endDate),
                                    minDate:
                                        new Date(props?.startDate) < new Date()
                                            ? new Date()
                                            : new Date(props?.startDate),
                                    maxDate: new Date(props?.endDate),
                                    singleDatePicker: true,
                                    locale: {
                                        format: "DD/MM/YYYY", // Specify the desired date format here
                                    },
                                }}
                                onCallback={handleCallbackedit}
                            >
                                <input type="text" className="form-control" />
                            </DateRangePicker>*/}
                        </div>
                    
                </div>)}
                {props?.editStatus === "ACTIVE" && (moment(lineitem_detail?.date_from) > moment()) && (
                    <>
                        <div className="col-lg-3">
                            <div className="form-group mb-3 select_country_container cmn_modal_field">
                                <label className="lbl_position" for="startdate">Start Date</label>
                                <DatePicker
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={lineitem_min_date}
                                    maxDate={lineitem_max_date}
                                    value={lineitem_start_date}
                                    onChange={date => setLineitem_start_date(date)}
                                    selected={lineitem_start_date}
                                />
                                {/*<DateRangePicker
                                    initialSettings={{
                                        startDate:
                                            new Date(props?.startDate) < new Date()
                                                ? new Date()
                                                : new Date(props?.startDate),
                                        endDate: new Date(props?.endDate),
                                        minDate:
                                            new Date(props?.startDate) < new Date()
                                                ? new Date()
                                                : new Date(props?.startDate),
                                        maxDate: new Date(props?.endDate),
                                        locale: {
                                            format: "DD/MM/YYYY", // Specify the desired date format here
                                        },
                                    }}
                                    onCallback={handleCallback}
                                >
                                    <input type="text" className="form-control" />
                                </DateRangePicker> */}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group mb-3 select_country_container cmn_modal_field">
                                <label className="lbl_position" for="startdate">End Date</label>
                                <DatePicker
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={lineitem_min_date}
                                    maxDate={lineitem_max_date}
                                    value={lineitem_end_date}
                                    onChange={date =>
                                        date > lineitem_start_date ?
                                            (setLineitem_end_date(date)) :
                                            (toast.error("End date must be after start date"))
                                    }
                                    selected={lineitem_end_date}
                                />
                            </div>
                        </div>
                    </>
                )}
                   <div className="col-lg-3">
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position" for="format">Pacing</label>
                        <select name="format_id" className="form-select"
                            value={selected_pacing}
                            onChange={(e) => setSelected_pacing(e.target.value)}
                        >
                            <option value="">--Select--</option>
                            {lineitem_pacing?.map((data,index) => {
                                return(
                                    <option key={index} value={data?.id}>{data?.description}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position" for="format">Pacing Rate</label>
                        <select name="format_id" className="form-select"
                            value={selected_pacing_rate}
                            onChange={(e) => setSelected_pacing_rate(e.target.value)}
                        >
                            <option value="">--Select--</option>
                            {lineitem_pacing_rate?.map((data,index) => {
                                return(
                                    <option key={index} value={data?.id}>{data?.description}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                {lineitem_current_start_date && lineitem_current_end_date && <p>Current: {
                    <>
                        <Moment format="DD/MM/YYYY">{lineitem_current_start_date}</Moment>
                        {"   -   "}
                        <Moment format="DD/MM/YYYY">{lineitem_current_end_date}</Moment>
                    </>
                } </p>}
                  
            </div>

            

            <div className="mb-3 row">
                <div className="col-lg-3">
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position " for="enddate">
                            Budget ({props?.dealCurrency}) (
                            {!lineitem_is_bonified && <p className="inline">Must be between   0 and {result + lineitem_detail?.inversion_budget}</p>}
                            )
                        </label>
                        <input type="number" name="inversion_budget" className="form-control" placeholder="Budget"
                            value={lineitem_budget}
                            onChange={(e) => setLineitem_budget(e.target.value)}
                            min={0}
                            max={!lineitem_is_bonified ? result + lineitem_budget : null}
                        />
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position " for="sellrate">Sell Rate ({props?.dealCurrency})</label>
                        <input type="number" name="inversion_sell_rate" className="form-control" placeholder="Sell Rate"
                            value={lineitem_sell_rate}
                            onChange={(e) => setLineitem_sell_rate(e.target.value)}
                            onBlur={getFormaulaData}
                        />

                    </div>
                </div>
                <div className="col-lg-3">
                    {!permission?.Permissions[22] && <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position " for="netmargin">Net Margin </label>
                        <select name="inversion_netMargin" className="form-control"
                            value={permission?.Permissions[22] ? "50" : lineitem_margin}
                            onChange={(e) => setLineitem_margin(e.target.value)}
                            disabled={permission?.Permissions[22]}
                        >
                            <option value="">--Select--</option>
                            <option value={5}>5%</option>
                            <option value={10}>10%</option>
                            <option value={15}>15%</option>
                            <option value={20}>20%</option>
                            <option value={25}>25%</option>
                            <option value={30}>30%</option>
                            <option value={35}>35%</option>
                            <option value={40}>40%</option>
                            <option value={45}>45%</option>
                            <option value={50}>50%</option>
                            <option value={55}>55%</option>
                            <option value={60}>60%</option>
                            <option value={65}>65%</option>
                            <option value={70}>70%</option>
                            <option value={75}>75%</option>
                            <option value={80}>80%</option>
                            <option value={85}>85%</option>
                            <option value={90}>90%</option>
                        </select>
                    </div>}
                </div>
            </div>
            {/*  */}
            <div className="col-lg-12">
                <div className="rates_list mt-3 mb-1">
                    {!permission?.Permissions[22] && <div className="form-group mb-2">
                        <div className='d-flex'><label className="mb-2" for="netrate">Net Rate ({props?.dealCurrency}): </label><span>{lineitem_net_rate?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                        })}</span></div>
                    </div>}
                    <div className="form-group mb-2"><label className=" " for="netrate">Budget USD: </label><span>{lineitem_budget_usd?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                    })}</span></div>
                    <div className="form-group mb-2"><label className=" " for="netrate">Sell Rate USD: </label><span>{lineitem_sell_rate_usd?.toLocaleString(undefined, {
                        minimumFractionDigits: 4,
                    })}</span></div>
                    {!permission?.Permissions[22] && <div className="form-group  mb-2"><label className=" " for="netrate">Net Rate USD: </label><span>{lineitem_net_rate_usd?.toLocaleString(undefined, {
                        minimumFractionDigits: 4,
                    })}</span></div>}
                    <div className="form-group  mb-2"><label className=" " for="goal">Goal: </label><span>{lineitem_goal?.toLocaleString()}</span></div>
                </div>
            </div>
            <label className="lable_title">Targeting</label>
            <Row className="mb-3">
                {valueInventory ? <Col lg={6}>
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position " htmlFor="country">
                            Country
                        </label>
                        <Multiselect
                            options={countries.map((i) => {
                                const name = i?.labelKey ?? "Unknown";
                                return { ...i, name };
                            })}
                            selectedValues={lineitem_selected_country}
                            onSelect={(e) => { handle_multiselect_countries(e) }}
                            singleSelect={true}
                            onRemove={(e) => handle_multiremove_country(e)}
                            className="react-datepicker-popper"
                            displayValue="name"
                        />
                    </div>
                </Col> :
                    <Col lg={6}>
                        <div className="form-group select_country_container cmn_modal_field mb-3">
                            <label className="lbl_position " htmlFor="country">
                                Country
                            </label>
                            <Multiselect
                                options={countries.map((i) => {
                                    const name = i?.labelKey ?? "Unknown";
                                    return { ...i, name };
                                })}
                                selectedValues={lineitem_selected_country}
                                onSelect={(e) => { handle_multiselect_countries(e) }}
                                onRemove={(e) => handle_multiremove_country(e)}
                                className="react-datepicker-popper"
                                displayValue="name"
                            />
                        </div>
                    </Col>
                }
                <Col lg={6}>
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position " htmlFor="country">
                            Devices
                        </label>

                        <Multiselect
                            options={devices.map((i) => {
                                const name = i?.name ?? "Unknown";
                                return { ...i, name };
                            })}
                            selectedValues={lineitem_selected_devices}
                            onSelect={(e) => handle_multiselect_devices(e)}
                            onRemove={(e) => handle_multiremove_device(e)}
                            displayValue="name"
                        />
                    </div>
                </Col>
            </Row>
            <label className="lable_title">Audience
            </label>
            <Row>

                <Col lg={4}>

                    <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position " for="netmargin">Gender </label>
                        <select name="inversion_netMargin" className="form-control"
                            value={lineitem_gender}
                            onChange={(e) => setLineitem_gender(e.target.value)}
                        >
                            <option value="">--Select--</option>
                            {
                                genders?.map((all_gender, index) => {
                                    return (
                                        <option value={all_gender?.id} key={index}>{all_gender?.description}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </Col>


                <Col lg={4}>
                    <div className="form-group select_country_container cmn_modal_field mb-3 multiselect_bottom">
                        <label className="lbl_position " htmlFor="country">
                            Age
                        </label>

                        <Multiselect
                            options={age_group.map((i) => {
                                const name = i?.age ?? "Unknown";
                                return { ...i, name };
                            })}
                            selectedValues={lineitem_selected_age}
                            onSelect={(e) => { handle_multiselect_age_range(e) }}
                            onRemove={(e) => handle_multiremove_age(e)}

                            displayValue="name"
                        />
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="form-group select_country_container cmn_modal_field mb-3 multiselect_bottom">
                        <label className="lbl_position " htmlFor="country">
                            Passion Point
                        </label>

                        <Multiselect
                            options={passion_points.map((i) => {
                                const name = i?.name ?? "Unknown";
                                return { ...i, name };
                            })}
                            selectedValues={lineitem_selected_passion_point}
                            onSelect={(e) => handle_multiselect_passion_points(e)}
                            onRemove={(e) => handle_multiremove_passion_points(e)}
                            displayValue="name"
                        />
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="form-check mt-2">
                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={lineitem_is_bonified} onChange={() => setLineitem_is_bonified(!lineitem_is_bonified)} />
                        <label className="form-check-label" for="flexCheckDefault">Is bonified</label>
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="form-check mt-2">
                        <input className="form-check-input" type="checkbox" id="isReach" checked={lineitem_is_reach} onChange={() => setLineitem_is_reach(!lineitem_is_reach)} />
                        <label className="form-check-label" for="flexCheckDefault">Reach</label>
                    </div>
                </Col>
            </Row>
            <div className="d-flex justify-content-end mt-4">
                {props?.editStatus !== "ACTIVE" && permission?.Permissions[5] && <button className='cmn_btn delete_cmn_btn' type="button" onClick={() => handlePopUpDelete(lineitem_detail.id)}>Delete</button>}
                <button type="button" className="me-4 ms-auto cancel_cm_btn" onClick={() => props.setEditLineItemModal(false)}>Cancel</button>
                <button className="cmn_btn ms-2 " type="submit" onClick={() => handle_update_lineitem()}>Update</button></div>

            <Modal
                show={confirm_delete}
                onHide={() => setConfirm_delete(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Are you sure !!
                    </Modal.Title>
                </Modal.Header> */}
                <Modal.Body className="p-4">
                    <div className='text-center mb-4'>
                        <FiAlertCircle className='alert_icon' size={64} />
                    </div>
                    <h4 className='text-center border-bottom pb-5'>Do you want to confirm the status change <br />
                        for selected IDs?
                    </h4>
                    <div className="d-flex justify-content-end mt-5">
                        <button className="me-4 cancel_cm_btn" onClick={() => setConfirm_delete(false)}>cancel</button>
                        <button className="cmn_btn ms-2 " onClick={() => handleDelete(deleted_lineitem_id)}>Confirm</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>


    )


}

export default EditLineItems